
@import '../../node_modules/window-engine/dist/window-engine.min.css';

.hunt-lascaris-turns-40 {

	.o-section {
		padding:0;
		min-height: 100vh;
	}

}

// https://codepen.io/_fbrz/pen/QwYLGV?editors=1100
.loading-screen {

	position: fixed;
	top:0;
	left:0;
	width: 100vw;
	height:100vh;
	background-color: white;
	z-index: 4;
	display: flex;
	//display: none;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: -webkit-radial-gradient(white, rgb(223, 223, 223));
  	background-size: 100%;

	#loader-text {
		position: relative;
		width:100%;
		text-align: center;
		color: #ffc107;
    	text-shadow: -1px -1px 4px #000;
		text-transform: uppercase;
		font-size: 20vw;
		font-weight: normal;
		font-style: normal;
		font-family: 'Nineteen Ninety Seven';
		font-family: 'Neon Sans';

	}

	#loader {
		animation: spin8bit 1s linear infinite;
		height: 10px;
		width: 10px;
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -5px;
	}
}

.gradient-1 {
	background: rgb(255,203,5);
	background: -moz-linear-gradient(90deg, rgba(255,203,5,1) 0%, rgba(245,168,194,1) 53%, rgba(96,126,194,1) 100%);
	background: -webkit-linear-gradient(90deg, rgba(255,203,5,1) 0%, rgba(245,168,194,1) 53%, rgba(96,126,194,1) 100%);
	background: linear-gradient(90deg, rgba(255,203,5,1) 0%, rgba(245,168,194,1) 53%, rgba(96,126,194,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffcb05",endColorstr="#607ec2",GradientType=1);
}

.gradient-2 {

	background: rgb(250,240,183);
	background: -moz-linear-gradient(90deg, rgba(250,240,183,1) 0%, rgba(227,238,212,1) 25%, rgba(202,232,224,1) 50%, rgba(219,215,224,1) 75%, rgba(234,201,225,1) 100%);
	background: -webkit-linear-gradient(90deg, rgba(250,240,183,1) 0%, rgba(227,238,212,1) 25%, rgba(202,232,224,1) 50%, rgba(219,215,224,1) 75%, rgba(234,201,225,1) 100%);
	background: linear-gradient(90deg, rgba(250,240,183,1) 0%, rgba(227,238,212,1) 25%, rgba(202,232,224,1) 50%, rgba(219,215,224,1) 75%, rgba(234,201,225,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#faf0b7",endColorstr="#eac9e1",GradientType=1);

}

.draggable-bounds {
	flex: 1;
	width: 100vw;
    height: 100vh;
	z-index: 2;
    overflow: hidden;
	position: relative;

	.windowGroup {
		.window {
			opacity: 1;
			overflow: hidden;
			height: auto;
			resize: both;
			min-width: 400px;
			max-width: 80vw;
			min-height: 200px;
			max-height: 70vh;
			background-color: transparent;
			//box-shadow: none;
			border-radius: 10px;
			border: 2px solid #ccc;
			box-shadow:
				0 0 0 5px #FFFFFF,
				0 0 0 10px #000,
				8px 8px 6px 10px rgba(0,0,0,.8);

			//[id$=header],
			> div:first-child {
				height: 60px;
				color:#000000;
				cursor: grab;
				align-items: center;
				background: -moz-linear-gradient(90deg, rgba(255,203,5,1) 0%, rgba(245,168,194,1) 53%, rgba(96,126,194,1) 100%);
				background: -webkit-linear-gradient(90deg, rgba(255,203,5,1) 0%, rgba(245,168,194,1) 53%, rgba(96,126,194,1) 100%);
				background: linear-gradient(90deg, rgba(255,203,5,1) 0%, rgba(245,168,194,1) 53%, rgba(96,126,194,1) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffcb05",endColorstr="#607ec2",GradientType=1);

				border-bottom: 2px solid #ccc;

				&:active {
					cursor: grabbing;

					.windowTitle {
						cursor: grabbing;
					}
				}
			}

			[id^=closeButton]  {
				padding: 0.25em 0.5em;
				color: #000;
				background: #FFFFFF;
				border:1px solid #ccc;
				border-radius: 4px;

				box-shadow: 0 0 0 5px #fff, 0 0 0 8px #000;
    			right: 0.5em;

				&:hover {
					color: #FFFFFF;
					background: #000;
				}
			}

			&.windowActive {
				[id$=header] {
					color:#000000;
				}

			}

			&.minimize {
				min-height: initial !important;

				.mainWindow {
					height: 0 !important;
					overflow: hidden !important;
					padding: 0 !important;
				}
			}

			[id^=closeButton] {
				bottom: auto;
				font-size: 18px;
				line-height: 1;

			}

			.windowTitle {
				font-size: 12px;
				font-family: 'Nineteen Ninety Seven';
				font-family: 'pixelmix';
				font-weight: normal;
				font-style: normal;
				margin-bottom: 0;
				cursor: grab;
			}

			.mainWindow {
				color: #000000;
				font-family: 'Retro Gaming';
    			font-weight: normal;
    			font-style: normal;

				height: 100%;

				background-image:
					url('data:image/svg+xml;utf8,<svg width="100" height="100" transform="rotate(0)" opacity="0.2" version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><g  fill="%23250E17"><circle cx="25" cy="25" r="12.5"/><circle cx="75" cy="75" r="12.5"/><circle cx="75" cy="25" r="12.5"/><circle cx="25" cy="75" r="12.5"/></g></svg>');
    			background-color:#c52754;
				background-image: none;
				background-color: #FFFFFF;
  				background-size: 10px, 100%;
				background-repeat: repeat;

				box-shadow: inset 0 0 0 10px white;

				display: flex;
				flex-direction: column;
				justify-content: space-between;

				.window-content {
					font-family: 'Retro Gaming';

					h1 {
						//font-size: 24px;
						font-size: 2vw;
						line-height: 1;
						margin-bottom: 1em;
						text-transform: uppercase;
						font-family: 'Retro Gaming';
						font-family: 'Nineteen Ninety Seven';
					}
				}

				.window-actions {
					position: relative;
					bottom: 10%;
					margin: 2em 0;
				}
			}
		}

		.window.window-notification {
			width:100%;
			max-width: 50vw;
			//width:  clamp(400px, 50vw, 50vw);
			left:10vw;
			top:10%;

			[id$=header] {
				background: rgb(250,240,183);
				background: -moz-linear-gradient(90deg, rgba(250,240,183,1) 0%, rgba(227,238,212,1) 25%, rgba(202,232,224,1) 50%, rgba(219,215,224,1) 75%, rgba(234,201,225,1) 100%);
				background: -webkit-linear-gradient(90deg, rgba(250,240,183,1) 0%, rgba(227,238,212,1) 25%, rgba(202,232,224,1) 50%, rgba(219,215,224,1) 75%, rgba(234,201,225,1) 100%);
				background: linear-gradient(90deg, rgba(250,240,183,1) 0%, rgba(227,238,212,1) 25%, rgba(202,232,224,1) 50%, rgba(219,215,224,1) 75%, rgba(234,201,225,1) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#faf0b7",endColorstr="#eac9e1",GradientType=1);
			}
		}

		.window.window-message {
			width:100%;
			max-width: 35vw;
			left:31vw;
			top:48%;

			[id$=header] {
				height: 80px;
			}
		}

		.window.window-celebration {
			width:100%;
			max-width: 18vw;
			left:70vw;
			top:27%;

			[id$=header] {
				background: #FFCB05;
			}

			.mainWindow {
				background-color: #e6e8e6;
				box-shadow: none;


				.window-content {

					img {
						position: relative;
						display: block;
						width: 100%;
						padding-top: 2em;
					}
				}

				.window-actions {
					p {
						font-size: 2vw;
						font-size: 20px;
						padding-left: 1em;
						padding-right: 1em;
						margin:0;
						font-family: 'pixelmix';
					}

					.loading-bar-retro {
						display: flex;
						padding: 0 1em;
						background-color: #FFFFFF;
						border-radius: 8px;
						border:1px solid #000000;
						height: 40px;
						width: 100%;
					}
				}
			}
		}

		@media only screen and (max-width: 1440px)  {

			.window {
				transform: scale(.8);
				transform: scale(1);
			}

			.window.window-notification {
				left:5vw;
			}

			.window.window-message {
				left:35vw;
				top:40%;
			}
		}

		@media only screen and (max-width: 1024px)  {

			.window {
				.windowTitle {
					font-size: 14px;
				}
			}

			.window.window-notification {
				min-width: initial;
				max-width: 65vw;
				//transform: scale(1);

				.mainWindow {

					.window-actions {
						margin-top: 0;
						margin-bottom: 0;
					}
				}
			}

			.window.window-message {
				left: 10vw;
				top: 55%;
				max-width: 70%;
			}

			.window.window-celebration {
				left: 50vw;
				top: 25%;

				.loader-bar {
					width: 100%;
					display: none !important;
				}

				.window-content {

					img {
						max-width: 100%;
						margin: 0 auto;
						padding-top: 0 !important;
						padding-bottom: 1em;
					}
				}

			}
		}

		@media only screen and (max-width: 680px)  {

			.window,
			.window.window-message,
			.window.window-notification {
				//left: 5%;
    			max-width: 90%;
				min-width: initial;
				//transform: scale(1);

				.mainWindow {

					.window-content {
						h1 {
							font-size: 20px !important;
							line-height: 1.2 !important;
						}
					}

					.window-actions {
						margin-top: 0;
						margin-bottom: 0;
					}
				}
			}

			.window.window-message {
				left:14vw;
				top: 34%;
			}

			.window.window-celebration {
				left:5vw;
				top: 58%;
				width: 100%;
				max-width: 80%;
				//transform: scale(.5);

				.window-content img {
					max-width: 200px;
					margin: 0 auto;
					padding-top: 0 !important;
					padding-bottom: 1em;
				}
			}

		}

		@media only screen and (max-width: 400px)  {

			.window.window-notification {
				top: 8%;
				max-width: 85%;
				transform: scale(.85);

				.window-actions {
					display: none;
				}
			}

			.window.window-message {
				top:40%;
				left:10vw;
				max-width: 85%;
				transform: scale(.85);
			}

			.window.window-celebration {

				top: 67%;
				max-width: 85%;
				transform: scale(.85);

				.window-content img {
					max-width: 200px;
					margin: 0 auto;
					padding-top: 0 !important;
					padding-bottom: 1em;
				}
			}
		}
	}

	footer {
		position: fixed;
		bottom: 0;
		background-color: rebeccapurple;
		width: 100%;
		height: 48px;
		padding: 4px;
		box-shadow: 0 2px 2px 1px black;

		[id^="button"] {
			margin-left: 20px;
			color: white;
			cursor: pointer;
			font-size: 30px;
			position: relative;
			bottom: 2px;
		}
	}

	.button-retro {
		position: relative;
		padding: .5em 1em;
		min-width: 150px;
		border:2px solid #000000;
		box-shadow:
			inset 0 0 0 2px white,
			inset 0 0 0 4px #222;

		&:hover {

			box-shadow:
			inset 0 0 0 2px #222,
			inset 0 0 0 4px #222;

		}

		&.style-2 {
			&:after {
				content: '';
				position: absolute;
				top: 5px;
				left: 5px;
				width: 93%;
				height: 77%;
				border:1px solid #000000;
				background-color: #FFFFFF;
			}
			&:before {
				content: '';
				position: absolute;
				top:0;
				left:0;
				width:100%;
				height:100%;

				background: linear-gradient(90deg, rgba(255,203,5,1) 0%, rgba(245,168,194,1) 53%, rgba(96,126,194,1) 100%);
			}

			span {
				display: block;
				z-index: 2;
				position: relative;
			}
		}
	}



}

.desktop-icons {
	position: absolute;
	right: 15vw;
	top: 10vh;
	width: auto;
	height: 80vh;
	display:none;

	ul {
		list-style: none;
		display: flex;
		flex-direction: column;
		position: relative;
		padding: 0;
		margin: 0;
		width: 100%;
		height: 100%;
		justify-content: center;
		align-items: center;

		li {
			text-align: center;
			color:#000000;
			font-size: 12px;
			font-family: 'Nineteen Ninety Seven';
			font-family: monospace;
			cursor: pointer;
			position: relative;
			margin: 2em auto;
			padding: 1em;
			width: 100%;
			min-width: 120px;
			border: 1px dotted #000000;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			background-color: rgba(0,0,0,.1);

			&:hover {
				border-color: #FFFFFF;
				background-color: rgba(0,0,0,.4);

				span {
					color: #FFFFFF;
				}

				img {
					filter: grayscale(1);
				}
			}

			div {
				position: relative;
				display: flex;
				justify-content: center;

				img {
					position: relative;
					display: block;
				}
			}
		}
	}
}

.context-menu-retro {

	div {
		cursor: pointer;
	}

	div span {
		font-family: 'pixelmix';
		font-weight: normal;
		font-style: normal;
	}
}

.form-rsvp {
	display: flex;
    flex-direction: column;

	.form-message,
	.form-processing {
		display: none;

		p {
			transition: all .5s ease-out;
		}
	}

	input {
		border-radius: 5px;
	}

	.form-message {
		text-align: center;

		&.error {
			color: #c52754;
		}
		&.success {
			color: forestgreen;

			p {
				font-size: 3em;
    			line-height: 1;
				padding-top: 1em;
			}
		}
	}

	.form-processing {
		.loader {
			animation: spin8bit 1s linear infinite;
			height: 10px;
			width: 10px;
			position: absolute;
			top: 50%;
			left: 50%;
			margin: -5px;
		}
	}
}

@keyframes spin8bit {
	0% {
	  box-shadow:
		0px -30px #000,
		10px -30px #000,
		20px -20px #000,
		30px -10px #000,
		30px 0px #000,
		30px 10px #000,
		20px 20px #000,
		10px 30px #000,
		0px 30px transparent,
		-10px 30px transparent,
		-20px 20px transparent,
		-30px 10px transparent,
		-30px 0px transparent,
		-30px -10px transparent,
		-20px -20px transparent,
		-10px -30px transparent;
	}
	6.25% {
	  box-shadow:
		0px -30px transparent,
		10px -30px #000,
		20px -20px #000,
		30px -10px #000,
		30px 0px #000,
		30px 10px #000,
		20px 20px #000,
		10px 30px #000,
		0px 30px #000,
		-10px 30px transparent,
		-20px 20px transparent,
		-30px 10px transparent,
		-30px 0px transparent,
		-30px -10px transparent,
		-20px -20px transparent,
		-10px -30px transparent;
	}
	12.5% {
	  box-shadow:
		0px -30px transparent,
		10px -30px transparent,
		20px -20px #000,
		30px -10px #000,
		30px 0px #000,
		30px 10px #000,
		20px 20px #000,
		10px 30px #000,
		0px 30px #000,
		-10px 30px #000,
		-20px 20px transparent,
		-30px 10px transparent,
		-30px 0px transparent,
		-30px -10px transparent,
		-20px -20px transparent,
		-10px -30px transparent;
	}
	18.75% {
	  box-shadow:
		0px -30px transparent,
		10px -30px transparent,
		20px -20px transparent,
		30px -10px #000,
		30px 0px #000,
		30px 10px #000,
		20px 20px #000,
		10px 30px #000,
		0px 30px #000,
		-10px 30px #000,
		-20px 20px #000,
		-30px 10px transparent,
		-30px 0px transparent,
		-30px -10px transparent,
		-20px -20px transparent,
		-10px -30px transparent;
	}
	25% {
	  box-shadow:
		0px -30px transparent,
		10px -30px transparent,
		20px -20px transparent,
		30px -10px transparent,
		30px 0px #000,
		30px 10px #000,
		20px 20px #000,
		10px 30px #000,
		0px 30px #000,
		-10px 30px #000,
		-20px 20px #000,
		-30px 10px #000,
		-30px 0px transparent,
		-30px -10px transparent,
		-20px -20px transparent,
		-10px -30px transparent;
	}
	31.25% {
	  box-shadow:
		0px -30px transparent,
		10px -30px transparent,
		20px -20px transparent,
		30px -10px transparent,
		30px 0px transparent,
		30px 10px #000,
		20px 20px #000,
		10px 30px #000,
		0px 30px #000,
		-10px 30px #000,
		-20px 20px #000,
		-30px 10px #000,
		-30px 0px #000,
		-30px -10px transparent,
		-20px -20px transparent,
		-10px -30px transparent;
	}
	37.5% {
	  box-shadow:
		0px -30px transparent,
		10px -30px transparent,
		20px -20px transparent,
		30px -10px transparent,
		30px 0px transparent,
		30px 10px transparent,
		20px 20px #000,
		10px 30px #000,
		0px 30px #000,
		-10px 30px #000,
		-20px 20px #000,
		-30px 10px #000,
		-30px 0px #000,
		-30px -10px #000,
		-20px -20px transparent,
		-10px -30px transparent;
	}
	43.75% {
	  box-shadow:
		0px -30px transparent,
		10px -30px transparent,
		20px -20px transparent,
		30px -10px transparent,
		30px 0px transparent,
		30px 10px transparent,
		20px 20px transparent,
		10px 30px #000,
		0px 30px #000,
		-10px 30px #000,
		-20px 20px #000,
		-30px 10px #000,
		-30px 0px #000,
		-30px -10px #000,
		-20px -20px #000,
		-10px -30px transparent;
	}
	50% {
	  box-shadow:
		0px -30px transparent,
		10px -30px transparent,
		20px -20px transparent,
		30px -10px transparent,
		30px 0px transparent,
		30px 10px transparent,
		20px 20px transparent,
		10px 30px transparent,
		0px 30px #000,
		-10px 30px #000,
		-20px 20px #000,
		-30px 10px #000,
		-30px 0px #000,
		-30px -10px #000,
		-20px -20px #000,
		-10px -30px #000;
	}
	56.25% {
	  box-shadow:
		0px -30px #000,
		10px -30px transparent,
		20px -20px transparent,
		30px -10px transparent,
		30px 0px transparent,
		30px 10px transparent,
		20px 20px transparent,
		10px 30px transparent,
		0px 30px transparent,
		-10px 30px #000,
		-20px 20px #000,
		-30px 10px #000,
		-30px 0px #000,
		-30px -10px #000,
		-20px -20px #000,
		-10px -30px #000;
	}
	62.5% {
	  box-shadow:
		0px -30px #000,
		10px -30px #000,
		20px -20px transparent,
		30px -10px transparent,
		30px 0px transparent,
		30px 10px transparent,
		20px 20px transparent,
		10px 30px transparent,
		0px 30px transparent,
		-10px 30px transparent,
		-20px 20px #000,
		-30px 10px #000,
		-30px 0px #000,
		-30px -10px #000,
		-20px -20px #000,
		-10px -30px #000;
	}
	68.75% {
	  box-shadow:
		0px -30px #000,
		10px -30px #000,
		20px -20px #000,
		30px -10px transparent,
		30px 0px transparent,
		30px 10px transparent,
		20px 20px transparent,
		10px 30px transparent,
		0px 30px transparent,
		-10px 30px transparent,
		-20px 20px transparent,
		-30px 10px #000,
		-30px 0px #000,
		-30px -10px #000,
		-20px -20px #000,
		-10px -30px #000;
	}
	75% {
	  box-shadow:
		0px -30px #000,
		10px -30px #000,
		20px -20px #000,
		30px -10px #000,
		30px 0px transparent,
		30px 10px transparent,
		20px 20px transparent,
		10px 30px transparent,
		0px 30px transparent,
		-10px 30px transparent,
		-20px 20px transparent,
		-30px 10px transparent,
		-30px 0px #000,
		-30px -10px #000,
		-20px -20px #000,
		-10px -30px #000;
	}
	81.25% {
	  box-shadow:
		0px -30px #000,
		10px -30px #000,
		20px -20px #000,
		30px -10px #000,
		30px 0px #000,
		30px 10px transparent,
		20px 20px transparent,
		10px 30px transparent,
		0px 30px transparent,
		-10px 30px transparent,
		-20px 20px transparent,
		-30px 10px transparent,
		-30px 0px transparent,
		-30px -10px #000,
		-20px -20px #000,
		-10px -30px #000;
	}
	87.5% {
	  box-shadow:
		0px -30px #000,
		10px -30px #000,
		20px -20px #000,
		30px -10px #000,
		30px 0px #000,
		30px 10px #000,
		20px 20px transparent,
		10px 30px transparent,
		0px 30px transparent,
		-10px 30px transparent,
		-20px 20px transparent,
		-30px 10px transparent,
		-30px 0px transparent,
		-30px -10px transparent,
		-20px -20px #000,
		-10px -30px #000;
	}
	93.75% {
	  box-shadow:
		0px -30px #000,
		10px -30px #000,
		20px -20px #000,
		30px -10px #000,
		30px 0px #000,
		30px 10px #000,
		20px 20px #000,
		10px 30px transparent,
		0px 30px transparent,
		-10px 30px transparent,
		-20px 20px transparent,
		-30px 10px transparent,
		-30px 0px transparent,
		-30px -10px transparent,
		-20px -20px transparent,
		-10px -30px #000;
	}
	100% {
	  box-shadow:
		0px -30px #000,
		10px -30px #000,
		20px -20px #000,
		30px -10px #000,
		30px 0px #000,
		30px 10px #000,
		20px 20px #000,
		10px 30px #000,
		0px 30px transparent,
		-10px 30px transparent,
		-20px 20px transparent,
		-30px 10px transparent,
		-30px 0px transparent,
		-30px -10px transparent,
		-20px -20px transparent,
		-10px -30px transparent;
	}
}

.loader-bar {
	width: 60vw;
	height: 12vw;
	display: grid;
	margin: 0 auto;
	border: 2px solid #222;
    border-radius: 10px;
	grid-template-columns: repeat(3,2vw) repeat(10, 3vw 2vw) repeat(2,2vw);
	grid-template-rows: repeat(6,2vw);
}

.block-border {
	background-color: rgb(50,50,50);
	background-color: transparent;
}

.block-meter {
	background-color: #E9C9DD;
}

.block-border:nth-of-type(1) {
	grid-row: 1;
	grid-column: 3/-3;
}

.block-border:nth-of-type(2) {
	grid-row: -2;
	grid-column: 3/-3;
}

.block-border:nth-of-type(3) {
	grid-row: 3/5;
	grid-column: 1;
}

.block-border:nth-of-type(4) {
	grid-row: 3/5;
	grid-column: -2;
}

.block-border:nth-of-type(5) {
	grid-row: 2;
	grid-column: 2;
}

.block-border:nth-of-type(6) {
	grid-row: 2;
	grid-column: -3;
}

.block-border:nth-of-type(7) {
	grid-row: 5;
	grid-column: 2;
}

.block-border:nth-of-type(8) {
	grid-row: 5;
	grid-column: -3;
}

.block-meter:nth-of-type(9) {
	grid-row: 3/5;
	grid-column: 4;
}

.block-meter:nth-of-type(10) {
	grid-row: 3/5;
	grid-column: 6;
}

.block-meter:nth-of-type(11) {
	grid-row: 3/5;
	grid-column: 8;
}

.block-meter:nth-of-type(12) {
	grid-row: 3/5;
	grid-column: 10;
}

.block-meter:nth-of-type(13) {
	grid-row: 3/5;
	grid-column: 12;
	opacity: 0;
	animation: blinky1 2s linear forwards;
}

.block-meter:nth-of-type(14) {
	grid-row: 3/5;
	grid-column: 14;
	opacity: 0;
	animation: blinky1 2s 4s linear forwards;
}

.block-meter:nth-of-type(15) {
	grid-row: 3/5;
	grid-column: 16;
	opacity: 0;
	animation: blinky1 2s 8s linear forwards;
}

.block-meter:nth-of-type(16) {
	grid-row: 3/5;
	grid-column: 18;
	opacity: 0;
	animation: blinky2 3s 14s linear infinite;
}

@keyframes blinky1 {
	99% {opacity: 0;}
	100% {opacity: 1;}
}

@keyframes blinky2 {
	49% {opacity: 0;}
	50% {opacity: 1;}
	90% {opacity: 1;}
}

@media only screen and (max-width: 680px)  {

	#loader-text {
    	text-shadow: -1px -1px 2px #000 !important;
	}

	.draggable-bounds {
		height: 160vh;
	}

	.form-rsvp {

		input {
			margin-bottom: 1em;
		}
	}

}
