.c-contact__item{
	
	letter-spacing:  			-1px;
	text-decoration: 			none;
	font-family:  				'Averta Semibold';
	transition:					transform .5s cubic-bezier(0.77, 0, 0.175, 1);
	display:  					inline-block;

	@include breakpoint(l){
		font-size: 					2vw;
		line-height: 				3vw;
	}
}







/* Hover states
   ========================================================================== */




.has-no-touch .c-contact__item:hover{
	transform:				translateX(10px);
}