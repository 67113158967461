/* ==========================================================================
   # ARROW component
   ========================================================================== */

.c-arrow{
	display: 					inline-block;
	align-self: 				center;
	display: 					flex;
	svg{
		width: 					50px;
	}
}




.c-arrow--left{
	svg{
		transform: 				scaleX(-1);
	}
}


.c-arrow--down{
	svg{
		transform: 				rotate(90deg);
	}
}