.c-video{

	position: 					absolute;
	top:						0;
	left:  						0;
	right:  					0;
	bottom:  					0;
	z-index:  					$z-ui;

	opacity:					0;
	display:  					none;



	.video-js{
		width: 100%!important;
	    height: 100%!important;
	    position: relative;


	    video{
	    	object-fit: cover;
		    display: block;
		    width: 100%;
		    height: 100%;
	    }

	}

}












/* States
   ========================================================================== */



.c-video.is-visible{

	display:  					block;

}