/* ==========================================================================
   #PRIMARY WEBSITE NAVIGATION
   ========================================================================== */




/**
 *
 * BURGER ICON
 *
 */


.c-nav__icon{

	position:							fixed;
	right:  							15px;
	top:								5px;
	background-color:					transparent;
	border:								none;
	padding:							0;
	z-index:  							$z-nav;
	cursor:  							pointer;

	width:								50px;
	height:  							50px;





	&:focus{
		outline:  						none;
	}

	&:active{
		transform:						scale(.8);
	}


	.icon{


		width:  		40px;
		height:  		30px;
		position: 		absolute;
		left:  			5px;
		top:			10px;


		&:focus{

			outline:  						none;
		}




		span{

			display: 		block;
			position: 		absolute;
			height: 		4px;
			width: 			40px;
			background: 	$color-white;
			opacity: 		1;
			left: 			0;
			transfrom:		rotate(0deg);
			transition:		.4s cubic-bezier(0.77, 0, 0.175, 1);

		}

		span:nth-child(1) {
			top: 0px;
		}

		span:nth-child(2), span:nth-child(3){
			top: 13px;
		}

		span:nth-child(4) {
			top: 26px;
		}
	}





	@include breakpoint(m){
		position:						fixed;
		right:  						30px;
		top:							20px;
	}
}


body.retro-theme-80s {
	.c-nav__icon {

		span {
			background-color: #000000;
		}
	}
}



body:not(.case-detail-is-visible){

	// .c-nav-darken{
	// //background-image:               linear-gradient(to bottom, rgba(0,0,0,.2) 0%, rgba(0,0,0,.1) 50%, rgba(0,0,0,0));
	//   position:                       absolute;
	//     left:                           0;
	//     right:                          0;
	//     top:							0;
	//     pointer-events:                 none;
	//     z-index:  						599;
	//     //height: 						100px;
	//     height: 						20vh;
	//     @include scrimGradient();
	//     //opacity:  						.2;
	// }


}







.c-nav-item{
	transform:						translateY(0px);
	transition:						transform .5s;
}

.c-nav-item-darken{
	opacity: 						0.1;
	transition:						opacity .5s;
}

.nav-is-hidden .c-nav-item{
	transform:						translateY(-80px);
}

.nav-is-hidden .c-nav-item-darken{
	opacity:  						0 !important;
}








/**
 *
 * NAVIGATION OVERLAY
 *
 */



 .c-nav{

 	position: 						absolute;
	top: 							0;
	left:							0;
	right:							0;
	bottom: 						0;
	overflow: hidden;
	z-index:  						$z-nav;


 }





 .c-nav__items{

 	position: 						absolute;
 	top: 							0;
	left:							0;
	right:							0;
	bottom: 						0;

	padding:						0;
  	margin:							0;

  	list-style-type: 				none;

  	display:  						flex;
	flex-direction: 				column;
	align-items: 					stretch;
	justify-content:				center;



  	@include breakpoint(l){
  		flex-direction: 			row;
  	}

 }






.c-nav__item{

	overflow:  						hidden;

  	display:  						flex;
  	flex-direction: 				column;
  	justify-content:				center;
  	flex-grow:						1;

  	width:  						100%;

  	background-color: 			lighten($color-black, 10%);
  	margin:						1px 0;





  	@include breakpoint(l){

  		flex:						1 auto;
  		width: 						25%;

  		background-color: 			transparent;

  	}
}





.c-nav__link{

	text-decoration: 				none;
	display:  						flex;
	cursor:  						pointer;
	align-items:					center;
	justify-content:				center;

	padding: 						1rem;

	flex:							1;




	&:focus{
		outline: 					none;
	}





	@include breakpoint(l){
		display:  					flex;
		padding:					0 0 20% 20%;
		justify-content:			flex-start;

	}

}





.c-nav__cta{
	display:  						none;

	@include breakpoint(l){
		background-color:  			$color-yellow;
		width:  					40px;
		height:  					40px;
		display:  					block;
		margin-top:					1rem;
		opacity:   					0;
		position: 					relative;


		&:after{
			position:  				absolute;
			display:  				block;
			content:				"";
			background:  			url('../images/arrow-right-black.svg') no-repeat 55% center;
			background-size:  		20%;
			top:					0;
			left:  					0;
			right:  				0;
			bottom:  				0;
			transform:				skewX(-20deg);
		}
	}
}







.c-nav__title{

	font-family:					"Averta Black";
	width:							100%;
	text-align:  					center;
	align-self:						center;

	font-size:              		5vh;
	line-height:              		5vh;

	@include breakpoint(l){
		font-size:              	2.5vw;
		line-height:                2.5vw;
		margin-bottom:  			1vw;
		text-align:  				left;

	}

	@include breakpoint(xl){
		font-size:              	3vw;
		line-height:                3vw;
	}
}










.c-nav__slices{
	position:  						absolute;
	top:							0;
	left:  							0;
	right:  						0;
	bottom:  						0;
}

.c-nav__slice{

  	display:  						none;


  	@include breakpoint(l){

  		display:  					block;
  		position: 					absolute;
	  	top:  						0;
	  	bottom:  					0;
	  	width:  					calc(25% - 2px);
	  	background-color: 			lighten($color-black, 10%);

  		transform-origin: 			0% 50%;
  		transform:				  	skewX(20deg);
  		transition: 				background-color 500ms;

  		width:  					calc(25% - 2px);

  		&.is-active{
  			background-color: 		#222;
  		}




  		&:first-child{
			left: -25%;
			background-color:		$color-black;
		}

		&:nth-child(2){
			left: 0%;
		}

		&:nth-child(3){
			left: 25%;
		}
		&:nth-child(4){
			left: 50%;
		}
		&:nth-child(5){
			left: 75%;
		}
		&:nth-child(6){
			left: 100%;
			background-color:		$color-black;
		}
  	}
  }


.c-nav--3-items{
	.c-nav__slice{
		width:  					calc(33% - 2px);

		&:first-child{
			left: -33%;
			background-color:		$color-black;
		}

		&:nth-child(2){
			left: 0%;
		}

		&:nth-child(3){
			left: 33%;
		}
		&:nth-child(4){
			left: 66%;
		}
		&:nth-child(5){
			left: 100%;
			background-color:		$color-black;
		}
	}




}



body.careers {
	.c-nav__icon {
		//opacity: 0;
		//pointer-events: none;

		span {
			background: #000000;
		}
	}
}





/* Visual states
   ========================================================================== */


.c-nav.is-hidden{
	display:  						none;
}





.nav-is-visible{

	.c-nav__icon .icon{

		span{
			background-color: $color-white !important;
		}

		span:nth-child(1) {
			top: 18px;
			width: 0%;
		 	left: 50%;
		}

		span:nth-child(2) {
		  transform:			rotate(45deg);
		}

		span:nth-child(3) {
		   transform:			rotate(-45deg);
		}


		span:nth-child(4) {
		  top: 18px;
		  width: 0%;
		  left: 50%;
		}


	}

}





@include breakpoint(l){


	.has-no-touch .c-nav.is-visible{

		.c-nav__link:hover{


			.c-nav__cta{
				opacity:  			1;
				transform:			translateX(0px);
			}

			.c-nav__info{
				opacity:  			1;
				transform: 			translateY(0px) skewX(-20deg);
			}
			.c-nav__title{
				transform: 			translateY(0px) skewX(-20deg);
			}
		}



		.c-nav__link{
			transform: 				translateY(30px);
		}



		.c-nav__info{
			opacity:  				0;
			transform:				translateX(-15px) skewX(-20deg);
			transition:				.2s cubic-bezier(0.77, 0, 0.175, 1);


			div{
				white-space:		nowrap;
    			overflow:			hidden;
    			text-overflow: 		ellipsis;
    			max-width:  		90%;
			}

		}


		.c-nav__title{
			transform: 				translateY(10px) skewX(-20deg);
			transition:				transform .35s cubic-bezier(0.77, 0, 0.175, 1);
		}


		.c-nav__cta{
			transform:				translateX(-15px);

			transition:				.2s cubic-bezier(0.77, 0, 0.175, 1);
		}


	}

}










.has-no-touch:not(.nav-is-visible) .c-nav__icon:hover{

	.icon span:nth-child(1), .icon span:nth-child(4) {
		transform:						translateX(-10%);
	}

}


.has-no-touch.nav-is-visible .c-nav__icon:hover{
	transform:						scale(.9);
}









/* Color states
   ========================================================================== */


.case-detail-is-visible{
	.c-nav__icon .icon span{
		@include breakpoint(l){
			background-color:  			$color-black;
		}
	}
}


/* CUSTOM NAVIGATION */

a.c-nav__title {
  text-decoration:none !important;
}

.c-nav__slice {
    display: none;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    width: calc(20% - 2px);
    transform-origin: 0% 50%;
    transform: skewX(20deg);
    transition: background-color 500ms;
    width: calc(20% - 2px);
  }
  .c-nav__slice.is-active {
    background-color: #222;
  }
  .c-nav__slice:first-child {
    left: -20%;
    background-color: #000000;
  }
  .c-nav__slice:nth-child(2) {
    left: 0%;
  }
  .c-nav__slice:nth-child(3) {
    left: 20%;
  }
  .c-nav__slice:nth-child(4) {
    left: 40%;
  }
  .c-nav__slice:nth-child(5) {
    left: 60%;
  }
  .c-nav__slice:nth-child(6) {
    left: 80%;
    background-color: #1f1f1f;
  }
  .c-nav__slice:nth-child(8) {
    left: 100%;
  }

@media only screen and (min-width: 1100px) {
  .has-no-touch .c-nav.is-visible .c-nav__info {
    max-height: 0;
    overflow: hidden;
    transition: all .17s cubic-bezier(.77,0,.175,1);
  }
  .has-no-touch .c-nav.is-visible .c-nav__link:hover .c-nav__info {
    max-height: 100%;
  }
}

