/* ==========================================================================
   #WIZARD TOGGLE COMPONENT
   ========================================================================== */

$wizard-text-color: 		$color-yellow;
$wizard-border-color: 		$color-yellow;
$wizard-border-height: 		2px;







.c-wizard-toggle{

	position:				relative;
	display:				block;
	background-color:  		transparent;
	border:					none;
	color:					$wizard-text-color;
	font-family:  			'Averta Semibold';
	font-size:				1.1rem;

	padding:  				5px 2rem 5px 0;
	margin:					0 1rem 2rem 1rem;

	user-select:			none;

	max-width: 				80vw;
    text-overflow: 			ellipsis;
    white-space: 			nowrap;
    overflow: 				hidden;


    &.c-wizzard-toggle--inline{
    	display:  			inline-block;
    	margin-left:  		.5rem;
    }



	&:after, &:before{
		content:			"";
		position: 			absolute;
	}


	&:after{
		right:  			0;
		top:				0;
		bottom:  			0;
		width:  			2rem;
		background:			url('../images/arrow-down-yellow.svg') no-repeat right 55%;
		background-size:	55%;
		transition:         transform 100ms;
	}

	&:before{
		left:				0;
		right:				0;
		bottom:				0;
		height:  			$wizard-border-height;
		background-color:	$wizard-border-color;
		
		transition: 		transform .5s cubic-bezier(0.77, 0, 0.175, 1);

		@include breakpoint(l){
			transform:		translate3d(calc(-100% - 1px), 0, 0);
		}
	}
}



@include breakpoint(m){

	.c-wizard-toggle{
		display:  			inline-block;
		font-size:			1.2rem;
		

	}
}


@include breakpoint(l){

	.c-wizard-toggle{
		display:  			inline-block;
		padding:  			.5rem 2rem .5rem 0;
		margin:				1rem;


		max-width: 			40vw;
	    text-overflow: 		ellipsis;
	    white-space: 		nowrap;
	    overflow: 			hidden;

	}

}

@include breakpoint(xl){

	.c-wizard-toggle{
		font-size:			1.4rem;
	}
}



.c-wizard-reset{
	display:  				inline-block;
	font-size:				.7rem;
	margin-left:  			1rem;
	text-decoration:  		none;
	font-style:  			italic;
	color:					$color-yellow;
	background-color:   	rgba(255,255,255,.15);
	padding:				3px 10px;
	//opacity:  				.5;
}





/* Custom states
   ========================================================================== */



.c-wizard-toggle:focus{
	outline:  				none;
}



.c-wizard-control{
	display:				none;
	opacity:  				0;
	transform:				translateY(50%);
	transition:				.7s transform cubic-bezier(.75,-0.5,0,1.75), .7s opacity;
}
.c-wizard-control.is-active{
	opacity:  				1;
	transform:				translateY(0);
}
.c-wizard-control.is-visible{
	display:				inline-block;
}


/* Hover states
   ========================================================================== */

.has-no-touch .c-wizard-toggle:hover{
	&:before{
		transform:				translate3d(0%, 0, 0);	
	}
	&:after{
		transform: 			translateY(5%);
	}
}


