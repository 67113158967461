/* ==========================================================================
   #INTRO
   ========================================================================== */


.c-intro{
	user-select:				none;
	position:  					absolute;
	top:						50%;
	left:  						50%;
	transform:					translateX(-50%) translateY(-50%);

	h1{
		// font-size:				11vw;
		// line-height:			11vw;
		letter-spacing:       	-3px;

		span{
			//opacity:  			0;
		}
	}



	@include breakpoint(l){
		
		h1{
			font-size:			5vw;
			line-height:		5vw;
		}
	}

}





