.c-cases{
	// position: 				absolute;
	// top:					0;
	// left:  					0;
	// right:  				0;
	// bottom:  				0;
	background-color:		$color-black;
	//margin-top:				10vh;
}

.c-cases__bg, .c-cases__bg__visual{
	position: 				absolute;
	top:					0;
	left:  					0;
	right:  				0;
	bottom:  				0;
}



body.cases:not(.detail-is-visible):not(.nav-is-visible){
	overflow:				hidden !important;
	position:  				fixed;
}