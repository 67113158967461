/* ==========================================================================
   #PAGE
   ========================================================================== */



body {
  font-size:                     16px;
	line-height:                   24px;


  @include breakpoint(m){
    font-size:                     $base-font-size;
    line-height:                   32px;
  }




	font-family:                   $base-font-family;
	color:                         $base-font-color;
	font-weight:                   $base-font-weight;
	background:                    $base-background-color;

  width:                         100%;
  //min-height:                    100vh;
  overflow-x:                    hidden;

     ::selection{
        background: lighten($color-black,50%);
    }

}




// Paragraph
p { 
    margin:                         0 0 $base-line-height 0; 
}

// Links
a {
  color:                            $base-link-color;
  text-decoration:                  underline;
  background-color:                 transparent;
  -webkit-text-decoration-skip:     objects;

  &:active, &:hover {
    outline-width:                  0;
    text-decoration:                none;
  }
}




// Horizontal Rules
hr, .hr {
  -moz-box-sizing:                  content-box;
  box-sizing:                       content-box;
  height:                           1px;
  background:                       #ccc;
  border:                           0;
  margin-top:                       $base-line-height;
  margin-bottom:                    $base-line-height;
}




/**
 *
 * Custom scrollbar for webkit browsers
 *
 */


$scrollbar-width:                     2px;


@include breakpoint(m){
  ::-webkit-scrollbar{
    width:                            $scrollbar-width;
    height:                           $scrollbar-width;
    -webkit-appearance:               none;
    -webkit-border-radius:            0;
  }


  ::-webkit-scrollbar-track{
    //background:                       rgba(0,0,0,.5);
    background:                       $color-white;
    -webkit-border-radius:            0px;

  }


  ::-webkit-scrollbar-thumb,::-webkit-scrollbar-thumb:vertical{
    //background:                       rgba(255,255,255,.8);
    background:                         rgba(0,0,0,.25);
    border:none;
    -webkit-border-radius:            0px;
  }

  ::-webkit-scrollbar-thumb:vertical:active{
    background:                       rgba(255,255,255,.8);
    border:                           none;
    -webkit-border-radius:            0;
  }


}








/**
 *
 * Loading state
 *
 */


.is-loading{
  cursor:                         wait;

  *{
    cursor:                         wait;
  }
}
