/* ==========================================================================
   #CONTAINERS
   ========================================================================== */

/**
 * Can be added to HTML container elements like:
 * <div>, <article>, <section>, <header>, <footer>, <form>, and more.
 *
 * adds common alignments, paddings and max width to any HTML element
 */




$container-gutter:               1rem;




.o-container{
    margin-left:            auto;
    margin-right:           auto;

    padding-left:           $container-gutter;
    padding-right:          $container-gutter;

}





/* Medium device size container
   ========================================================================== */


@include breakpoint(m) {

    .o-container { 
        max-width:              $breakpoint-m; 

    }


    .o-container\@m{
        max-width:              $breakpoint-m; 
        padding-left:           $container-gutter;
        padding-right:          $container-gutter;
        margin-left:            auto;
        margin-right:           auto;

    }


}




/* Large device size container
   ========================================================================== */


@include breakpoint(l) {

    .o-container { 
        max-width:              $breakpoint-l; 

    }


     .o-container\@l{
        max-width:              $breakpoint-l; 
        padding-left:           $container-gutter;
        padding-right:          $container-gutter;
        margin-left:            auto;
        margin-right:           auto;

    }


}




/* Extra large device size container
   ========================================================================== */


@include breakpoint(xl) {

   .o-container { 
        max-width:              $breakpoint-xl; 

    }


     .o-container\@xl{
        max-width:              $breakpoint-xl; 
        padding-left:           $container-gutter;
        padding-right:          $container-gutter;
        margin-left:            auto;
        margin-right:           auto;

    }

}