/* ==========================================================================
   #GLOBAL SETTINGS
   ========================================================================== */




/* Global Colors
   ========================================================================== */

$color-black:		#000;
$color-white:		#fff;
$color-grey:		#ccc;
$color-yellow:		#ffc600;
$color-dark:		#222;




/* Global Typography
   ========================================================================== */

$base-background-color:                         $color-black !default;
$base-font-family:                              "Averta Regular";
$base-font-size:                                18px !default;
$base-line-height:                              28px !default;
$base-font-weight:                              400 !default;
$base-font-color:                               $color-white !default;

$base-link-color:                               #000 !default;
$base-link-hover-color:                         #000 !default;





/* Global Spacing
   ========================================================================== */

$base-spacing-unit:       						1.2vh;
$base-spacing-unit-s:  							calc($base-spacing-unit / 2);
$base-spacing-unit-m: 							$base-spacing-unit;
$base-spacing-unit-l: 							$base-spacing-unit * 2;
$base-spacing-unit-xl:  						$base-spacing-unit * 4;





/* Breakpoints
   ========================================================================== */

$breakpoint-m:                                  700px 	!default;
$breakpoint-l:                                  1100px 	!default;
$breakpoint-xl:                                 1400px 	!default;




/* Z-index
   ========================================================================== */


$z-ui:											900;
$z-nav:											800;
$z-overlay:										700;
$z-foreground:									600;
$z-background:									500;