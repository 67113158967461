/* ==========================================================================
   #TBWA LOGO on the top of the page
   ========================================================================== */



.c-tbwalogo{

	position:						fixed;
	left:  							15px;
	top:							15px;
	text-decoration:  				none;
	cursor:  						pointer;


	z-index:  						600;


	img{
		height:  					30px;
	}

	.retro-name {
		display: none;
	}



	@include breakpoint(m){
		position: 					fixed;
		top: 						30px;
		left:						30px;
	}

	.c-tbwalogo--dark{
		display:  					none;
	}

}

body.careers {

	.c-tbwalogo--dark {
		display: none;
	}

	.c-tbwalogo--white {
		display: none;
	}

	&.scroll-active {
		.c-tbwalogo {
			opacity: 0;
			pointer-events: none;
		}
	}
}

body.retro-theme-80s {


	.c-tbwalogo {

		.c-tbwalogo--dark {
			display: none !important;
		}

		.c-tbwalogo--white {
			display: none !important;
		}

		display: flex;
		flex-direction: row;

		.retro-name {
			display: block;
			font-size: 30px;
			font-family: 'Nineteen Ninety Seven';
			font-family: 'pixelmix';
			font-weight: bold;
			font-style: normal;

			span {
				color: #ffcb05;
				left: -15px;
				position: relative;
			}
		}
	}
}


/* Style variants
   ========================================================================== */

.c-tbwalogo.is-inversed{

	.c-tbwalogo--white{
		display:  						none;
	}

	.c-tbwalogo--dark{
		display:  						block;
	}

}
