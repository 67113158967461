
.c-footer{
	display:  				block;
	position: 				fixed;
	left:					15px;
	bottom:  				15px;
	font-size:				.6rem;
	transition:				transform .5s;
	transform:				translateY(0px);
}



.nav-is-hidden .c-footer{
	transform:				translateY(80px);
}




@include breakpoint(l){


	.c-footer{
		left:					30px;
		bottom:  				20px;
		font-size:				.7rem;
	}



}

body.careers,
.scroll-active {
	.c-footer {
		color: black !important;

		a {
			color: black !important;
		}
	}
}

body.retro-theme-80s {

	.c-footer {
		//display: none;
		font-family: 'Retro Gaming';
		font-weight: normal;
		font-style: normal;

		color: black !important;

		a {
			color: black !important;
		}
	}

	&.scroll-active {

		.c-footer {
			color: white !important;

			a {
				color: white !important;
			}
		}

	}

	@media only screen and (max-width: 680px)  {
		.c-footer {
			display: none;
		}
	}
}
