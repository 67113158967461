/* ==========================================================================
   #HEADINGS
   ========================================================================== */





$base-heading-font-family:                     "Averta Semibold";

$base-h1-font-size:                             55px !default;
$base-h1-font-weight:                           400 !default;
$base-h1-color:                                 #000 !default;

$base-h2-font-size:                             40px !default;
$base-h2-font-weight:                           400 !default;
$base-h2-color:                                 #000 !default;

$base-h3-font-size:                             23px !default;
$base-h3-font-weight:                           400 !default;
$base-h3-color:                                 #000 !default;

$base-h4-font-size:                             18px !default;
$base-h4-font-weight:                           400 !default;
$base-h4-color:                                 #000 !default;

$base-h5-font-size:                             16px !default;
$base-h5-font-weight:                           400 !default;
$base-h5-color:                                 #000 !default;

$base-h6-font-size:                             16px !default;
$base-h6-font-weight:                           400 !default;
$base-h6-color:                                 #000 !default;






h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family:                                  $base-heading-font-family;
  margin:                                       0;
  //margin-bottom:                                $base-line-height;
}


h1, .h1{
    @include                                    font-size($base-h1-font-size, $base-h1-font-size); 
    color:                                      $base-h1-color;
    font-weight:                                $base-h1-font-weight;
    font-family:                                "Averta Black";

    letter-spacing:                             -1px;

}


h2, .h2{
    @include                                    font-size($base-h2-font-size,$base-h2-font-size); 
    color:                                      $base-h2-color;
    font-weight:                                $base-h2-font-weight;
    font-family:                                "Averta Black";

    letter-spacing:                             -1px;
}


h3, .h3{
    @include                                    font-size($base-h3-font-size,$base-h3-font-size); 
    color:                                      $base-h3-color;
    font-weight:                                $base-h3-font-weight;

}


h4, .h4{
    @include                                    font-size($base-h4-font-size,$base-h4-font-size); 
    color:                                      $base-h4-color;
    font-weight:                                $base-h4-font-weight;

}

h5, .h5{
    @include                                    font-size($base-h5-font-size); 
    color:                                      $base-h5-color;
    font-weight:                                $base-h5-font-weight;

}

h6, .h6{
    @include                                    font-size($base-h6-font-size); 
    color:                                      $base-h6-color;
    font-weight:                                $base-h6-font-weight;

}



@include breakpoint(l){
    
    h1, .h1{
        font-size:                              5.5vw;
        line-height:                            5vw;
        letter-spacing:                         -2px;
    } 



    h2, .h2{
        font-size:                              4vw;
        line-height:                            4vw;
    }  




    // h3, .h3{
    //     font-size:                              1.5vw;
    //     line-height:                            1.8vw;
    // }




            
}





@include breakpoint(l){

    h1:not(.js-home-intro-title), .h1, h2, .h2{
        span{
            transform:  skewX(-20deg);
        }
            
    }






}




@include breakpoint(xl){


    h3, .h3{
        @include                                    font-size(28px,38px); 
        color:                                      $base-h3-color;
        font-weight:                                $base-h3-font-weight;

    }



}