/* ==========================================================================
   #LINK COMPONENT
   ========================================================================== */



.c-links{

	.c-button{
		margin: 								0 1.5rem 1rem 0;


		@include breakpoint(m){
			margin: 							0 2.5vw 1rem 0;
		}
	}

	&.c-links--right{
		.c-button{
			margin: 							0 0 1rem 2.5vw;
		}
	}

}









.c-link{
	text-decoration:  						none;
	display: 								inline-block;
	margin: 								0 1.5rem 1rem 0;
	position:  								relative;
	overflow:  								hidden;

	&:not(.c-link--button){
		padding-bottom:  						5px;
	}


	@include breakpoint(m){
		margin: 							0 2.5vw 1rem 0;
	}

}

.c-links--right .c-link{
	margin: 								0 0 1rem 2.5vw;
}










/* Visual states
   ========================================================================== */



.c-link--yellow{

}





.c-link--black{
	
}










.has-no-touch .c-link:not(.c-link--button){



	&.c-link--yellow{
		&:before{
			background-color:				$color-yellow;
		}
	}


	&.c-link--white{
		&:before{
			background-color:				$color-white;
		}
	}


	&.c-link--black{
		&:before{
			background-color:				$color-black;
		}
	}


	&:before{
		content:						"";
		position: 						absolute;
		left:							0;
		right:							0;
		bottom:							0;
		height:  						2px;
		
		transition: 					transform .5s cubic-bezier(0.77, 0, 0.175, 1);

		@include breakpoint(l){
			transform:					translate3d(calc(-100% - 1px), 0, 0);
		}
	}


	&:hover{
		&:before{
			transform:				translate3d(0%, 0, 0);	
		}
	}

}