/* ==========================================================================
   #HELPERS
   ========================================================================== */




// General Resets
.u-no-margin { margin: 0; }
.u-no-padding { padding: 0; }
.u-no-float { float: none; }
.u-no-background { background: transparent; }
.u-no-border { border: 0; }
.u-no-select {
  -webkit-user-select: none;  
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}
.u-no-pointer-events{
  pointer-events: none;
}





// Font Styles
.u-font-normal { font-style: normal; }
.u-font-italic { font-style: italic; }


// Text Modifications
.u-uppercase { text-transform: uppercase; }
.u-lowercase { text-transform: lowercase; }
.u-capitalize { text-transform: capitalize; }


// Text Alignments
.u-text-left { text-align: left; }
.u-text-right { text-align: right; }
.u-text-center { text-align: center; }




// Positions
.u-relative { position: relative; }
.u-absolute { position: absolute; }
.u-static { position: static; }
.u-fixed { position: fixed; }





// Display Types
.u-none { display: none; }
.u-block { display: block; }
.u-inline-block { display: inline-block; }
.u-inline { display: inline; }





// Flex Types
.u-flex { display: flex; }
.u-flex-row { flex-direction: row; }
.u-flex-column { flex-direction: column; }
.u-flex-space-around { justify-content: space-around; }
.u-flex-space-between { justify-content: space-between; }
.u-flex-start { justify-content: flex-start; }
.u-flex-center { justify-content: center; }
.u-flex-end { justify-content: flex-end; }
.u-flex-wrap { flex-wrap: wrap; }
.u-flex-nowrap { flex-wrap: nowrap; }
.u-flex-grow { flex-grow: 1; }
.u-flex-shrink { flex-shrink: 1; }
.u-flex-align-center{ align-items: center; }






// Floats
.u-left { float: left; }
.u-right { float: right; }


// Alignment
.u-center {
  float: none;
  margin-left: auto;
  margin-right: auto;
}






// Lists
.u-list-unstyled {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    margin: 0;
    padding: 0;
  }
}





// Images
.u-img-fluid { width: 100%; }





// Disable Element
.u-disabled, [disabled] {
  pointer-events: none;
  cursor: not-allowed;
  opacity: .5;
}





// Animations
.u-animation, .u-animate { @include animation(.5s); }
.u-animation-infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}





// clearfix
.u-clear:before, .u-clear:after { content: " "; display: table; } .u-clear:after { clear: both; }





// Spacing utils

.u-margin--s{ margin: $base-spacing-unit-s}
.u-margin--m{ margin: $base-spacing-unit-m}
.u-margin--l{ margin: $base-spacing-unit-l}
.u-margin--xl{ margin: $base-spacing-unit-xl}

.u-margin-x--s{ margin-left: $base-spacing-unit-s; margin-right: $base-spacing-unit-s}
.u-margin-x--m{ margin-left: $base-spacing-unit-m; margin-right: $base-spacing-unit-m}
.u-margin-x--l{ margin-left: $base-spacing-unit-l; margin-right: $base-spacing-unit-l}
.u-margin-x--xl{ margin-left: $base-spacing-unit-xl; margin-right: $base-spacing-unit-xl}

.u-margin-y--s{ margin-top: $base-spacing-unit-s; margin-bottom: $base-spacing-unit-s}
.u-margin-y--m{ margin-top: $base-spacing-unit-m; margin-bottom: $base-spacing-unit-m}
.u-margin-y--l{ margin-top: $base-spacing-unit-l; margin-bottom: $base-spacing-unit-l}
.u-margin-y--xl{ margin-top: $base-spacing-unit-xl; margin-bottom: $base-spacing-unit-xl}

.u-margin-top--s{ margin-top: $base-spacing-unit-s}
.u-margin-top--m{ margin-top: $base-spacing-unit-m}
.u-margin-top--l{ margin-top: $base-spacing-unit-l}
.u-margin-top--xl{ margin-top: $base-spacing-unit-xl}

.u-margin-right--s{ margin-right: $base-spacing-unit-s}
.u-margin-right--m{ margin-right: $base-spacing-unit-m}
.u-margin-right--l{ margin-right: $base-spacing-unit-l}
.u-margin-right--xl{ margin-right: $base-spacing-unit-xl}

.u-margin-bottom--s{ margin-bottom: $base-spacing-unit-s}
.u-margin-bottom--m{ margin-bottom: $base-spacing-unit-m}
.u-margin-bottom--l{ margin-bottom: $base-spacing-unit-l}
.u-margin-bottom--xl{ margin-bottom: $base-spacing-unit-xl}

.u-margin-left--s{ margin-left: $base-spacing-unit-s}
.u-margin-left--m{ margin-left: $base-spacing-unit-m}
.u-margin-left--l{ margin-left: $base-spacing-unit-l}
.u-margin-left--xl{ margin-left: $base-spacing-unit-xl}




.u-padding--s{ padding: $base-spacing-unit-s}
.u-padding--m{ padding: $base-spacing-unit-m}
.u-padding--l{ padding: $base-spacing-unit-l}
.u-padding--xl{ padding: $base-spacing-unit-xl}

.u-padding-x--s{ padding-left: $base-spacing-unit-s; padding-right: $base-spacing-unit-s}
.u-padding-x--m{ padding-left: $base-spacing-unit-m; padding-right: $base-spacing-unit-m}
.u-padding-x--l{ padding-left: $base-spacing-unit-l; padding-right: $base-spacing-unit-l}
.u-padding-x--xl{ padding-left: $base-spacing-unit-xl; padding-right: $base-spacing-unit-xl}

.u-padding-y--s{ padding-top: $base-spacing-unit-s; padding-bottom: $base-spacing-unit-s}
.u-padding-y--m{ padding-top: $base-spacing-unit-m; padding-bottom: $base-spacing-unit-m}
.u-padding-y--l{ padding-top: $base-spacing-unit-l; padding-bottom: $base-spacing-unit-l}
.u-padding-y--xl{ padding-top: $base-spacing-unit-xl; padding-bottom: $base-spacing-unit-xl}

.u-padding-top--s{ padding-top: $base-spacing-unit-s}
.u-padding-top--m{ padding-top: $base-spacing-unit-m}
.u-padding-top--l{ padding-top: $base-spacing-unit-l}
.u-padding-top--xl{ padding-top: $base-spacing-unit-xl}

.u-padding-right--s{ padding-right: $base-spacing-unit-s}
.u-padding-right--m{ padding-right: $base-spacing-unit-m}
.u-padding-right--l{ padding-right: $base-spacing-unit-l}
.u-padding-right--xl{ padding-right: $base-spacing-unit-xl}

.u-padding-bottom--s{ padding-bottom: $base-spacing-unit-s}
.u-padding-bottom--m{ padding-bottom: $base-spacing-unit-m}
.u-padding-bottom--l{ padding-bottom: $base-spacing-unit-l}
.u-padding-bottom--xl{ padding-bottom: $base-spacing-unit-xl}

.u-padding-left--s{ padding-left: $base-spacing-unit-s}
.u-padding-left--m{ padding-left: $base-spacing-unit-m}
.u-padding-left--l{ padding-left: $base-spacing-unit-l}
.u-padding-left--xl{ padding-left: $base-spacing-unit-xl}











/* Medium device size container
   ========================================================================== */

@include breakpoint(m) {


    .u-no-margin\@m { margin: 0; }
    .u-no-padding\@m { padding: 0; }


    // Text Alignments
    .u-text-left\@m  { text-align: left; }
    .u-text-right\@m  { text-align: right; }
    .u-text-center\@m  { text-align: center; }


    // Display Types
    .u-none\@m { display: none; }
    .u-block\@m { display: block; }
    .u-inline-block\@m { display: inline-block; }
    .u-inline\@m { display: inline; }


    // Flex Types
    .u-flex\@m { display: flex; }
    .u-flex-row\@m { flex-direction: row; }
    .u-flex-column\@m { flex-direction: column; }
    .u-flex-space-around\@m { justify-content: space-around; }
    .u-flex-space-between\@m { justify-content: space-between; }
    .u-flex-start\@m { justify-content: flex-start; }
    .u-flex-center\@m { justify-content: center; }
    .u-flex-end\@m { justify-content: flex-end; }
    .u-flex-wrap\@m { flex-wrap: wrap; }
    .u-flex-nowrap\@m { flex-wrap: nowrap; }
    .u-flex-grow\@m { flex-grow: 1; }
    .u-flex-shrink\@m { flex-shrink: 1; }
    .u-flex-align-center\@m{ align-items: center; }





    // Spacing utils

    .u-margin--s\@m{ margin: $base-spacing-unit-s}
    .u-margin--m\@m{ margin: $base-spacing-unit-m}
    .u-margin--l\@m{ margin: $base-spacing-unit-l}
    .u-margin--xl\@m{ margin: $base-spacing-unit-xl}

    .u-margin-x--s\@m{ margin-left: $base-spacing-unit-s; margin-right: $base-spacing-unit-s}
    .u-margin-x--m\@m{ margin-left: $base-spacing-unit-m; margin-right: $base-spacing-unit-m}
    .u-margin-x--l\@m{ margin-left: $base-spacing-unit-l; margin-right: $base-spacing-unit-l}
    .u-margin-x--xl\@m{ margin-left: $base-spacing-unit-xl; margin-right: $base-spacing-unit-xl}

    .u-margin-y--s\@m{ margin-top: $base-spacing-unit-s; margin-bottom: $base-spacing-unit-s}
    .u-margin-y--m\@m{ margin-top: $base-spacing-unit-m; margin-bottom: $base-spacing-unit-m}
    .u-margin-y--l\@m{ margin-top: $base-spacing-unit-l; margin-bottom: $base-spacing-unit-l}
    .u-margin-y--xl\@m{ margin-top: $base-spacing-unit-xl; margin-bottom: $base-spacing-unit-xl}

    .u-margin-top--s\@m{ margin-top: $base-spacing-unit-s}
    .u-margin-top--m\@m{ margin-top: $base-spacing-unit-m}
    .u-margin-top--l\@m{ margin-top: $base-spacing-unit-l}
    .u-margin-top--xl\@m{ margin-top: $base-spacing-unit-xl}

    .u-margin-right--s\@m{ margin-right: $base-spacing-unit-s}
    .u-margin-right--m\@m{ margin-right: $base-spacing-unit-m}
    .u-margin-right--l\@m{ margin-right: $base-spacing-unit-l}
    .u-margin-right--xl\@m{ margin-right: $base-spacing-unit-xl}

    .u-margin-bottom--s\@m{ margin-bottom: $base-spacing-unit-s}
    .u-margin-bottom--m\@m{ margin-bottom: $base-spacing-unit-m}
    .u-margin-bottom--l\@m{ margin-bottom: $base-spacing-unit-l}
    .u-margin-bottom--xl\@m{ margin-bottom: $base-spacing-unit-xl}

    .u-margin-left--s\@m{ margin-left: $base-spacing-unit-s}
    .u-margin-left--m\@m{ margin-left: $base-spacing-unit-m}
    .u-margin-left--l\@m{ margin-left: $base-spacing-unit-l}
    .u-margin-left--xl\@m{ margin-left: $base-spacing-unit-xl}




    .u-padding--s\@m{ padding: $base-spacing-unit-s}
    .u-padding--m\@m{ padding: $base-spacing-unit-m}
    .u-padding--l\@m{ padding: $base-spacing-unit-l}
    .u-padding--xl\@m{ padding: $base-spacing-unit-xl}

    .u-padding-x--s\@m{ padding-left: $base-spacing-unit-s; padding-right: $base-spacing-unit-s}
    .u-padding-x--m\@m{ padding-left: $base-spacing-unit-m; padding-right: $base-spacing-unit-m}
    .u-padding-x--l\@m{ padding-left: $base-spacing-unit-l; padding-right: $base-spacing-unit-l}
    .u-padding-x--xl\@m{ padding-left: $base-spacing-unit-xl; padding-right: $base-spacing-unit-xl}

    .u-padding-y--s\@m{ padding-top: $base-spacing-unit-s; padding-bottom: $base-spacing-unit-s}
    .u-padding-y--m\@m{ padding-top: $base-spacing-unit-m; padding-bottom: $base-spacing-unit-m}
    .u-padding-y--l\@m{ padding-top: $base-spacing-unit-l; padding-bottom: $base-spacing-unit-l}
    .u-padding-y--xl\@m{ padding-top: $base-spacing-unit-xl; padding-bottom: $base-spacing-unit-xl}

    .u-padding-top--s\@m{ padding-top: $base-spacing-unit-s}
    .u-padding-top--m\@m{ padding-top: $base-spacing-unit-m}
    .u-padding-top--l\@m{ padding-top: $base-spacing-unit-l}
    .u-padding-top--xl\@m{ padding-top: $base-spacing-unit-xl}

    .u-padding-right--s\@m{ padding-right: $base-spacing-unit-s}
    .u-padding-right--m\@m{ padding-right: $base-spacing-unit-m}
    .u-padding-right--l\@m{ padding-right: $base-spacing-unit-l}
    .u-padding-right--xl\@m{ padding-right: $base-spacing-unit-xl}

    .u-padding-bottom--s\@m{ padding-bottom: $base-spacing-unit-s}
    .u-padding-bottom--m\@m{ padding-bottom: $base-spacing-unit-m}
    .u-padding-bottom--l\@m{ padding-bottom: $base-spacing-unit-l}
    .u-padding-bottom--xl\@m{ padding-bottom: $base-spacing-unit-xl}

    .u-padding-left--s\@m{ padding-left: $base-spacing-unit-s}
    .u-padding-left--m\@m{ padding-left: $base-spacing-unit-m}
    .u-padding-left--l\@m{ padding-left: $base-spacing-unit-l}
    .u-padding-left--xl\@m{ padding-left: $base-spacing-unit-xl}


}





/* Large device size container
   ========================================================================== */

@include breakpoint(l) {

     .u-no-margin\@l { margin: 0; }
    .u-no-padding\@l { padding: 0; }



     // Text Alignments
    .u-text-left\@l  { text-align: left; }
    .u-text-right\@l  { text-align: right; }
    .u-text-center\@l  { text-align: center; }


    // Display Types
    .u-none\@l { display: none; }
    .u-block\@l { display: block; }
    .u-inline-block\@l { display: inline-block; }
    .u-inline\@l { display: inline; }



    // Flex Types
    .u-flex\@l { display: flex; }
    .u-flex-row\@l { flex-direction: row; }
    .u-flex-column\@l { flex-direction: column; }
    .u-flex-space-around\@l { justify-content: space-around; }
    .u-flex-space-between\@l { justify-content: space-between; }
    .u-flex-start\@l { justify-content: flex-start; }
    .u-flex-center\@l { justify-content: center; }
    .u-flex-end\@l { justify-content: flex-end; }
    .u-flex-wrap\@l { flex-wrap: wrap; }
    .u-flex-nowrap\@l { flex-wrap: nowrap; }
    .u-flex-grow\@l { flex-grow: 1; }
    .u-flex-shrink\@l { flex-shrink: 1; }
    .u-flex-align-center\@l{ align-items: center; }




    // Spacing utils

    .u-margin--s\@l{ margin: $base-spacing-unit-s}
    .u-margin--m\@l{ margin: $base-spacing-unit-m}
    .u-margin--l\@l{ margin: $base-spacing-unit-l}
    .u-margin--xl\@l{ margin: $base-spacing-unit-xl}

    .u-margin-x--s\@l{ margin-left: $base-spacing-unit-s; margin-right: $base-spacing-unit-s}
    .u-margin-x--m\@l{ margin-left: $base-spacing-unit-m; margin-right: $base-spacing-unit-m}
    .u-margin-x--l\@l{ margin-left: $base-spacing-unit-l; margin-right: $base-spacing-unit-l}
    .u-margin-x--xl\@l{ margin-left: $base-spacing-unit-xl; margin-right: $base-spacing-unit-xl}

    .u-margin-y--s\@l{ margin-top: $base-spacing-unit-s; margin-bottom: $base-spacing-unit-s}
    .u-margin-y--m\@l{ margin-top: $base-spacing-unit-m; margin-bottom: $base-spacing-unit-m}
    .u-margin-y--l\@l{ margin-top: $base-spacing-unit-l; margin-bottom: $base-spacing-unit-l}
    .u-margin-y--xl\@l{ margin-top: $base-spacing-unit-xl; margin-bottom: $base-spacing-unit-xl}

    .u-margin-top--s\@l{ margin-top: $base-spacing-unit-s}
    .u-margin-top--m\@l{ margin-top: $base-spacing-unit-m}
    .u-margin-top--l\@l{ margin-top: $base-spacing-unit-l}
    .u-margin-top--xl\@l{ margin-top: $base-spacing-unit-xl}

    .u-margin-right--s\@l{ margin-right: $base-spacing-unit-s}
    .u-margin-right--m\@l{ margin-right: $base-spacing-unit-m}
    .u-margin-right--l\@l{ margin-right: $base-spacing-unit-l}
    .u-margin-right--xl\@l{ margin-right: $base-spacing-unit-xl}

    .u-margin-bottom--s\@l{ margin-bottom: $base-spacing-unit-s}
    .u-margin-bottom--m\@l{ margin-bottom: $base-spacing-unit-m}
    .u-margin-bottom--l\@l{ margin-bottom: $base-spacing-unit-l}
    .u-margin-bottom--xl\@l{ margin-bottom: $base-spacing-unit-xl}

    .u-margin-left--s\@l{ margin-left: $base-spacing-unit-s}
    .u-margin-left--m\@l{ margin-left: $base-spacing-unit-m}
    .u-margin-left--l\@l{ margin-left: $base-spacing-unit-l}
    .u-margin-left--xl\@l{ margin-left: $base-spacing-unit-xl}




    .u-padding--s\@l{ padding: $base-spacing-unit-s}
    .u-padding--m\@l{ padding: $base-spacing-unit-m}
    .u-padding--l\@l{ padding: $base-spacing-unit-l}
    .u-padding--xl\@l{ padding: $base-spacing-unit-xl}

    .u-padding-x--s\@l{ padding-left: $base-spacing-unit-s; padding-right: $base-spacing-unit-s}
    .u-padding-x--m\@l{ padding-left: $base-spacing-unit-m; padding-right: $base-spacing-unit-m}
    .u-padding-x--l\@l{ padding-left: $base-spacing-unit-l; padding-right: $base-spacing-unit-l}
    .u-padding-x--xl\@l{ padding-left: $base-spacing-unit-xl; padding-right: $base-spacing-unit-xl}

    .u-padding-y--s\@l{ padding-top: $base-spacing-unit-s; padding-bottom: $base-spacing-unit-s}
    .u-padding-y--m\@l{ padding-top: $base-spacing-unit-m; padding-bottom: $base-spacing-unit-m}
    .u-padding-y--l\@l{ padding-top: $base-spacing-unit-l; padding-bottom: $base-spacing-unit-l}
    .u-padding-y--xl\@l{ padding-top: $base-spacing-unit-xl; padding-bottom: $base-spacing-unit-xl}

    .u-padding-top--s\@l{ padding-top: $base-spacing-unit-s}
    .u-padding-top--m\@l{ padding-top: $base-spacing-unit-m}
    .u-padding-top--l\@l{ padding-top: $base-spacing-unit-l}
    .u-padding-top--xl\@l{ padding-top: $base-spacing-unit-xl}

    .u-padding-right--s\@l{ padding-right: $base-spacing-unit-s}
    .u-padding-right--m\@l{ padding-right: $base-spacing-unit-m}
    .u-padding-right--l\@l{ padding-right: $base-spacing-unit-l}
    .u-padding-right--xl\@l{ padding-right: $base-spacing-unit-xl}

    .u-padding-bottom--s\@l{ padding-bottom: $base-spacing-unit-s}
    .u-padding-bottom--m\@l{ padding-bottom: $base-spacing-unit-m}
    .u-padding-bottom--l\@l{ padding-bottom: $base-spacing-unit-l}
    .u-padding-bottom--xl\@l{ padding-bottom: $base-spacing-unit-xl}

    .u-padding-left--s\@l{ padding-left: $base-spacing-unit-s}
    .u-padding-left--m\@l{ padding-left: $base-spacing-unit-m}
    .u-padding-left--l\@l{ padding-left: $base-spacing-unit-l}
    .u-padding-left--xl\@l{ padding-left: $base-spacing-unit-xl}


}





/* Extra large device size container
   ========================================================================== */

@include breakpoint(xl) {


     // Text Alignments
    .u-text-left\@xl  { text-align: left; }
    .u-text-right\@xl  { text-align: right; }
    .u-text-center\@xl  { text-align: center; }


    // Display Types
    .u-none\@xl { display: none; }
    .u-block\@xl { display: block; }
    .u-inline-block\@xl { display: inline-block; }
    .u-inline\@xl { display: inline; }



    // Flex Types
    .u-flex\@xl { display: flex; }
    .u-flex-row\@xl { flex-direction: row; }
    .u-flex-column\@xl { flex-direction: column; }
    .u-flex-space-around\@xl { justify-content: space-around; }
    .u-flex-space-between\@xl { justify-content: space-between; }
    .u-flex-start\@xl { justify-content: flex-start; }
    .u-flex-center\@xl { justify-content: center; }
    .u-flex-end\@xl { justify-content: flex-end; }
    .u-flex-wrap\@xl { flex-wrap: wrap; }
    .u-flex-nowrap\@xl { flex-wrap: nowrap; }
    .u-flex-grow\@xl { flex-grow: 1; }
    .u-flex-shrink\@xl { flex-shrink: 1; }
    .u-flex-align-center\@xl{ align-items: center; }





    // Spacing utils

    .u-margin--s\@xl{ margin: $base-spacing-unit-s}
    .u-margin--m\@xl{ margin: $base-spacing-unit-m}
    .u-margin--l\@xl{ margin: $base-spacing-unit-l}
    .u-margin--xl\@xl{ margin: $base-spacing-unit-xl}

    .u-margin-x--s\@xl{ margin-left: $base-spacing-unit-s; margin-right: $base-spacing-unit-s}
    .u-margin-x--m\@xl{ margin-left: $base-spacing-unit-m; margin-right: $base-spacing-unit-m}
    .u-margin-x--l\@xl{ margin-left: $base-spacing-unit-l; margin-right: $base-spacing-unit-l}
    .u-margin-x--xl\@xl{ margin-left: $base-spacing-unit-xl; margin-right: $base-spacing-unit-xl}

    .u-margin-y--s\@xl{ margin-top: $base-spacing-unit-s; margin-bottom: $base-spacing-unit-s}
    .u-margin-y--m\@xl{ margin-top: $base-spacing-unit-m; margin-bottom: $base-spacing-unit-m}
    .u-margin-y--l\@xl{ margin-top: $base-spacing-unit-l; margin-bottom: $base-spacing-unit-l}
    .u-margin-y--xl\@xl{ margin-top: $base-spacing-unit-xl; margin-bottom: $base-spacing-unit-xl}

    .u-margin-top--s\@xl{ margin-top: $base-spacing-unit-s}
    .u-margin-top--m\@xl{ margin-top: $base-spacing-unit-m}
    .u-margin-top--l\@xl{ margin-top: $base-spacing-unit-l}
    .u-margin-top--xl\@xl{ margin-top: $base-spacing-unit-xl}

    .u-margin-right--s\@xl{ margin-right: $base-spacing-unit-s}
    .u-margin-right--m\@xl{ margin-right: $base-spacing-unit-m}
    .u-margin-right--l\@xl{ margin-right: $base-spacing-unit-l}
    .u-margin-right--xl\@xl{ margin-right: $base-spacing-unit-xl}

    .u-margin-bottom--s\@xl{ margin-bottom: $base-spacing-unit-s}
    .u-margin-bottom--m\@xl{ margin-bottom: $base-spacing-unit-m}
    .u-margin-bottom--l\@xl{ margin-bottom: $base-spacing-unit-l}
    .u-margin-bottom--xl\@xl{ margin-bottom: $base-spacing-unit-xl}

    .u-margin-left--s\@xl{ margin-left: $base-spacing-unit-s}
    .u-margin-left--m\@xl{ margin-left: $base-spacing-unit-m}
    .u-margin-left--l\@xl{ margin-left: $base-spacing-unit-l}
    .u-margin-left--xl\@xl{ margin-left: $base-spacing-unit-xl}




    .u-padding--s\@xl{ padding: $base-spacing-unit-s}
    .u-padding--m\@xl{ padding: $base-spacing-unit-m}
    .u-padding--l\@xl{ padding: $base-spacing-unit-l}
    .u-padding--xl\@xl{ padding: $base-spacing-unit-xl}

    .u-padding-x--s\@xl{ padding-left: $base-spacing-unit-s; padding-right: $base-spacing-unit-s}
    .u-padding-x--m\@xl{ padding-left: $base-spacing-unit-m; padding-right: $base-spacing-unit-m}
    .u-padding-x--l\@xl{ padding-left: $base-spacing-unit-l; padding-right: $base-spacing-unit-l}
    .u-padding-x--xl\@xl{ padding-left: $base-spacing-unit-xl; padding-right: $base-spacing-unit-xl}

    .u-padding-y--s\@xl{ padding-top: $base-spacing-unit-s; padding-bottom: $base-spacing-unit-s}
    .u-padding-y--m\@xl{ padding-top: $base-spacing-unit-m; padding-bottom: $base-spacing-unit-m}
    .u-padding-y--l\@xl{ padding-top: $base-spacing-unit-l; padding-bottom: $base-spacing-unit-l}
    .u-padding-y--xl\@xl{ padding-top: $base-spacing-unit-xl; padding-bottom: $base-spacing-unit-xl}

    .u-padding-top--s\@xl{ padding-top: $base-spacing-unit-s}
    .u-padding-top--m\@xl{ padding-top: $base-spacing-unit-m}
    .u-padding-top--l\@xl{ padding-top: $base-spacing-unit-l}
    .u-padding-top--xl\@xl{ padding-top: $base-spacing-unit-xl}

    .u-padding-right--s\@xl{ padding-right: $base-spacing-unit-s}
    .u-padding-right--m\@xl{ padding-right: $base-spacing-unit-m}
    .u-padding-right--l\@xl{ padding-right: $base-spacing-unit-l}
    .u-padding-right--xl\@xl{ padding-right: $base-spacing-unit-xl}

    .u-padding-bottom--s\@xl{ padding-bottom: $base-spacing-unit-s}
    .u-padding-bottom--m\@xl{ padding-bottom: $base-spacing-unit-m}
    .u-padding-bottom--l\@xl{ padding-bottom: $base-spacing-unit-l}
    .u-padding-bottom--xl\@xl{ padding-bottom: $base-spacing-unit-xl}

    .u-padding-left--s\@xl{ padding-left: $base-spacing-unit-s}
    .u-padding-left--m\@xl{ padding-left: $base-spacing-unit-m}
    .u-padding-left--l\@xl{ padding-left: $base-spacing-unit-l}
    .u-padding-left--xl\@xl{ padding-left: $base-spacing-unit-xl}


}

