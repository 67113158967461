/* ==========================================================================
   #APP COMPONENT
   ========================================================================== */



body{
	// position:  	absolute;
	// top:		0;
	// left:  		0;
	// height:  	100vh;
	// width:  	100vw;
	overflow:   hidden;

	-webkit-tap-highlight-color: rgba(0,0,0,0);


	&:not(.nav-is-visible){
		min-height:  	100vh;
	}
}


.o-scrollbar-wrapper{
	position: fixed;
	top:		0;
	left:  		0;
	bottom:  	0;
	width: 100vw;
}

.o-scrollbar{
	position:  	absolute !important;
	top:		0;
	left:  		0;
	bottom:  	0;
	right: 		0;
}


.o-scrollbar-inner{
	width:  100vw;
	overflow:  	hidden;
	position: 	relative;
}


.scrollbar-thumb {
    display: block;
    height: 70px;
    width: 100%;
    background: #ccc;
    position: relative;
    opacity: 0;
    -webkit-transition: background-color 0.2s cubic-bezier(0.4, 0.8, 0.74, 1),opacity 1s cubic-bezier(0.26, 1.04, 0.54, 1);
    -o-transition: background-color 0.2s cubic-bezier(0.4, 0.8, 0.74, 1),opacity 1s cubic-bezier(0.26, 1.04, 0.54, 1);
    transition: background-color 0.2s cubic-bezier(0.4, 0.8, 0.74, 1),opacity 1s cubic-bezier(0.26, 1.04, 0.54, 1);
}

.scrollbar-track {
    position: fixed;
    right: 2px;
    top: 2px;
    bottom:	2px;
    width: 2px;


    &.show{


    	.scrollbar-thumb{
    		opacity: 1;
    	}
    }
 }


/**
 *
 *  STATES
 *
 */




.detail-is-visible{
	background-color:					$color-white;
}



.nav-is-visible{
	overflow:							hidden;
}





/**
 *
 * Animation optimal
 *
 */


 .js-view{
 	will-change: opacity; 
 	will-change: transfrom; 
 }





 .js-view-content{
 	will-change: opacity; 
 }




.js-draggable-parent{
	will-change: scroll-position;
}




.js-slash{
	will-change: path;
}






/**
 *
 * Loading states
 *
 */




.js-preload{
	opacity:  				0;
	transition:				opacity 1s;
}



.js-preload.is-loaded{
	opacity:  				1;
}

.js-preload.is-loading{
	pointer-events:			none;
	visibility:       		hidden;
	cursor:					wait;
}
