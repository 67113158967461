.c-transition{

	position: 				fixed;
	top: 					0;
	left: 					calc(-0.364 * 50vh);
	right: 					calc(-0.364 * 50vh);
	bottom: 				0;
	
	z-index:  				$z-foreground;

	transform: 				translateX(-100%) ;


	&:before{
		content: 			"";
		position: 			absolute;
		left: 				0;
		right: 				0;
		bottom: 			0;
		top: 				0;
		background-color: 	$color-black;
		transform: 			skewX(20deg) ;
	}

}