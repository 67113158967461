#backslash-accordion input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  /* Accordion styles */
  #backslash-accordion .tabs {
    width: 100%;
    overflow: hidden;
  }

  #backslash-accordion .tab {
    width: 100%;
    color: white;
    overflow: hidden;
  }
  #backslash-accordion .tab-label {
    position: relative;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
            justify-content: center;
    padding: 2em;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    /* Icon */
  }
  #backslash-accordion .tab-label:hover {
  }

  #backslash-accordion .tab-label.triggers-daily {
    background: #ffc824;
  }

  #backslash-accordion .tab-label.triggers-africa {
    background: #555555;
  }

  #backslash-accordion .tab-label.triggers-reports {
    background: #FFFFFF;
  }

  #backslash-accordion .tab-label::after {
    content: "\276F";
    color: #FFFFFF;
    height: 2em;
    font-size: 3em;
    line-height: 2em;
    text-align: center;
    -webkit-transition: all .35s;
    transition: all .35s;
    position: absolute;
    right: 2em;
    top: .5em;
  }

  #backslash-accordion .tab-label.triggers-daily::after {
    color: #000000;
  }
  #backslash-accordion .tab-label.triggers-africa::after {
    color: #FFFFFF;
  }
  #backslash-accordion .tab-label.triggers-reports::after {
    color: #ffc824;
  }

  #backslash-accordion .tab-content {
    max-height: 0;
    padding: 0 1em;
    color: #2c3e50;
    background: white;
    -webkit-transition: all .35s;
    transition: all .35s;
  }
  #backslash-accordion .tab-close {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
            justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    background: #2c3e50;
    cursor: pointer;
  }
  #backslash-accordion .tab-close:hover {
    background: #1a252f;
  }

  #backslash-accordion input:checked + .tab-label {
    background: #1a252f;
  }

  #backslash-accordion input:checked + .tab-label.triggers-daily {
    background: #ffc824;
  }

  #backslash-accordion .tab-label.triggers-daily.tab-close:hover h2,
  #backslash-accordion input:checked + .tab-label.triggers-daily h2 {
    color: #000000;
  }

  #backslash-accordion input:checked + .tab-label::after {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  #backslash-accordion input:checked ~ .tab-content {
    max-height: 100vh;
    padding: 1em;
    color: #000000;
    background:#ffc824;
  }

  #backslash-accordion input#chck2:checked ~ .tab-content {
    background:#ffffff;
  }

  #backslash-accordion input#chck3:checked ~ .tab-content {
    background:#ffffff;
  }

  @media  (max-width: 796px) {

    #backslash-accordion .tab-label {
      justify-content: flex-start;
      text-align: left;
    }
    #backslash-accordion .tab-label::after {
      right: .5em;
      top: .15em;
    }
  }

  /* BACKSLASH CAROUSEL */
  .carrousel {
    text-align: center;
    padding: 0;
    padding-bottom: 4em;
    height: auto;
    max-width: 100%;
    margin: auto;
    position: relative;
    overflow: hidden;
  }
  .carrousel h2 {
    margin: 0;
    margin-top: -1.7em;
    padding: 0;
    font-size: 1em;
    text-align: center;
    color: #bbbbbb;
  }
  .carrousel .slides {
    width: 500%;
    left: 0;
    padding-left: 0;
    padding-top: 1em;
    overflow: visible;
    list-style: none;
    position: relative;

    -webkit-transition: transform .5s;
    -moz-transition: transform .5s;
    -o-transition: transform .5s;
    transition: transform .5s;
  }
  .carrousel .slides li {
    width: 20%;
    position: relative;
    float: left;
  }
  .carrousel li p{
      margin: 0 1em;
      display: inline-block;
  }
  .carrousel li p a {
    display: block;
  }
  .carrousel li q {
    max-width: 90%;
    margin: auto;
    color: #666666;
    font-size: 1.3em;
    font-weight: bold;
  }
  .carrousel li img {
    width: 100%;
    max-width: 200px;
    height: auto;
    object-fit: cover;
    margin-left: -1.5em;
    margin-right: 0.5em;
    vertical-align: middle;
  }
  .carrousel li span.author {
    margin-top:0.5em;
    font-size: 1.2em;
    color: #777777;
    display: block;
  }
  .carrousel .slidesNavigation {
    display: block;
    list-style: none;
    text-align: center;
    bottom: 1em;
    /*--- Centering trick---*/
      /* Absolute positioning*/
      position: absolute;
      /* Abosulte positioning*/
      width: 130px; /*This width  is the addition of the width of all the navigations dots - So in this case is   104 because the navigation dots are 26px (width:10px and 6px marginleft + 6 px marginright) and there are 4 dots so 4x26=104 */
      left: 50%; /*Centering de element*/
      margin-left: -52px; /*adjusting the centering by applying a negative margin of half of the width*/
  }
  .carrousel input {
    display: none;
  }
  .carrousel .slidesNavigation label {
    float: left;
    margin: 6px;
    display: block;
    height: 10px;
    width: 10px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    border: solid 2px #000000;
    font-size: 0;
  }
  /* You have to repeat this with each slide
  TODO: make it easier with SCSS
  25% movement 100/slides-num
  */
  #radio-1:checked ~ .slides,
  .radio-1:checked ~ .slides {
    transform: translateX(0%);
  }
  #radio-2:checked ~ .slides,
  .radio-2:checked ~ .slides {
    transform: translateX(-25%);
    transform: translateX(-20%);
  }
  #radio-3:checked ~ .slides,
  .radio-3:checked ~ .slides {
    transform: translateX(-50%);
    transform: translateX(-40%);
  }
  #radio-4:checked ~ .slides ,
  .radio-4:checked ~ .slides {
    transform: translateX(-75%);
    transform: translateX(-60%);
  }

  #radio-5:checked ~ .slides ,
  .radio-5:checked ~ .slides {
    transform: translateX(-75%);
    transform: translateX(-80%);
  }


  .carrousel .slidesNavigation label:hover {
     cursor: pointer;
  }
  /* You have to repeat this with each slide/dot */
  .carrousel #radio-1:checked ~ .slidesNavigation label#dotForRadio-1,
  .carrousel #radio-2:checked ~ .slidesNavigation label#dotForRadio-2,
  .carrousel #radio-3:checked ~ .slidesNavigation label#dotForRadio-3,
  .carrousel #radio-4:checked ~ .slidesNavigation label#dotForRadio-4,
  .carrousel #radio-5:checked ~ .slidesNavigation label#dotForRadio-5,

  .carrousel #radio-1:checked ~ .slidesNavigation label.dotForRadio-1,
  .carrousel #radio-2:checked ~ .slidesNavigation label.dotForRadio-2,
  .carrousel #radio-3:checked ~ .slidesNavigation label.dotForRadio-3,
  .carrousel #radio-4:checked ~ .slidesNavigation label.dotForRadio-4,
  .carrousel #radio-5:checked ~ .slidesNavigation label.dotForRadio-5 {
    background: #ffc600;
  }

  .carrousel #radio-1:checked ~ .slidesNavigation label#dotForRadio-1,
  .carrousel .radio-1:checked ~ .slidesNavigation label.dotForRadio-1{
    background: #000000;
  }

  .carrousel #radio-2:checked ~ .slidesNavigation label#dotForRadio-2,
  .carrousel .radio-2:checked ~ .slidesNavigation label.dotForRadio-2{
    background: #000000;
  }

  .carrousel #radio-3:checked ~ .slidesNavigation label#dotForRadio-3,
  .carrousel .radio-3:checked ~ .slidesNavigation label.dotForRadio-3{
    background: #000000;
  }

  .carrousel #radio-4:checked ~ .slidesNavigation label#dotForRadio-4,
  .carrousel .radio-4:checked ~ .slidesNavigation label.dotForRadio-4{
    background: #000000;
  }

  .carrousel #radio-5:checked ~ .slidesNavigation label#dotForRadio-5,
  .carrousel .radio-5:checked ~ .slidesNavigation label.dotForRadio-5{
    background: #000000;
  }


  @media  (max-width: 480px) {
    /** /
    .carrousel li p {
      padding-left: 0.5em;
      padding-right: 0.5em;
    }
    .carrousel li q {
        font-size: 1em;
    }
    .carrousel li img {
       width:2em;
       margin-left: -1em;
       margin-right: 0.25em;
    }
    /**/
  }


  /* BACKSLASH CARD */

  .carrousel .slides .slide .card {
      display: inline-block;
      background-color:transparent;
      border-radius: 0;
      width: 100%;
      max-width: 350px;
      height: auto;
      margin: 0 2rem;
      transition: 0.3s ease-in-out;
  }

  .carrousel .slides .slide .card iframe {
      margin: 0 !important;
      box-shadow: 2px 2px 22px rgba(0, 0,0, 0.2);
      transition: 0.3s ease-in-out;
  }

  .carrousel .slides .slide .card:hover iframe {
      box-shadow: 2px 12px 32px rgba(0, 0,0, 0.5);
  }

  .carrousel .slides .slide .card .img {
      background: url('https://i.imgur.com/58l3TGO.jpg') center/cover;
      height: 200px;
      width: 100%;
  }

  .carrousel .slides .slide .card .card-top {
      padding: 20px;
  }

  .carrousel .slides .slide .card .top-text {
    line-height: 18px;
  }

  .carrousel .slides .slide .card .title {
      color: #000000;
  }

  .carrousel .slides .slide .card .bottom-text {
    line-height: 18px;
    padding: 30px 0px 10px 20px;
  }

  .carrousel .slides .slide .card .card-buttons {
      margin-top: 20px;
      margin-bottom: 30px;
      padding: 0px 0px 0px 10px;

  }

  .carrousel .slides .slide .card .card-buttons a,
  .carrousel .slides .slide .card .card-buttons button {
    background: none;
    text-transform: uppercase;
    border: 1px solid #000000;
  }

  .carrousel.backslash-reports li img {
    transition: 0.3s ease-in-out;
    border: 2px solid #000;
  }
  .carrousel.backslash-reports li img:hover {
    border: 2px dashed #000;
  }

  @media  (max-width: 796px) {
    .carrousel .slides .slide .card:nth-of-type(even) {
      display:none;
    }
  }


  @media  (max-width: 480px) {
    .carrousel .slides .slide .card {
      margin: 0;
    }

    .carrousel .slides .slide .card iframe {
      min-width: 100% !important;
    }

    #backslash-accordion input:checked ~ .tab-content {
      max-height: 100%;
    }

    #backslash-accordion .carrousel.backslash-reports .slides,
    #backslash-accordion .carrousel.backslash-reports .slides li {
      width:100%
    }
    .carrousel.backslash-reports li p {
      margin: 1.5em 0;
    }

    .carrousel.backslash-reports li img {
      margin: 0;
      transition: 0.3s ease-in-out;
      border: 2px dashed #000;
    }

    .carrousel.backslash-reports li img:hover {
      border: 2px solid #000;
    }
  }


  /* BACKSLASH VIDEOS */
  .backslash-videos .tag {
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: bold;
    padding: 3px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .backslash-videos .tag-primary {
    background-color: #FFD100;
  }

  .backslash-videos .wrapper {
    padding: 26px 0;
  }

  .card-h {
    scroll-snap-align: center;
    background: white;
    border: 1px solid #555555;
    width: 240px;
    height: 320px;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }

  .card-h a{
    text-decoration: none;
  }
  .card-h .card-thumbnail {
    height: 190px;
    position: relative;
    overflow: hidden;
  }
  .card-h .card-thumbnail img,
  .card-h .card-thumbnail iframe {
    display: block;
    width: 100%;
    height: 190px;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .card-h .card-tag {
    position: absolute;
    top: 10px;
    left: 10px;
  }
  .card-h .card-body {
    padding: 14px 20px 0;
    -webkit-box-flex: 1;
            flex: 1;
  }
  .card-h .card-foot {
    padding: 0 20px 14px 20px;
  }
  .card-h .card-title {
    font-size: 14px;
    font-weight: bold;
    position: relative;
    overflow: hidden;
    white-space: normal;
    margin-bottom: 6px;
    max-height: 52px;
  }
  .card-h .card-name {
    max-height: 42px;
    line-height: 16px;
  }
  .card-h .card-rating {
    self-align: flex-end;
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    font-size: 12px;
    font-weight: bold;
  }
  .card-h .card-rating .stars {
    margin-top: -2px;
    margin-right: 10px;
  }
  .card-h .card-specialties {
    text-transform: uppercase;
    position: absolute;
    font-size: 10px;
    bottom: 0;
    left: 0;
    right: 0;
    color: white;
    padding: 14px 20px 14px;
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.6)), to(rgba(0, 0, 0, 0)));
    background: linear-gradient(to top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
  }

  .scrollable-container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
  @media (min-width: 992px) {
    .scrollable-container {
      max-width: 960px;
    }
  }
  @media (min-width: 1200px) {
    .scrollable-container {
      max-width: 1140px;
    }
  }

  .scrollable-cards {
    -ms-scroll-snap-type: x mandatory;
        scroll-snap-type: x mandatory;
    display: -webkit-box;
    display: flex;
    overflow-x: scroll;
    display: flex;
    padding: 5px;
    -webkit-overflow-scrolling: touch;
  }
  .scrollable-cards .card-h {
        flex-shrink: 0;
        margin: 5px 5px;
  }
  .scrollable-cards .card-h:last-child {
    border-right: 10px solid #F6F6F6;
  }

  @media (min-width: 992px) {
    .scrollable-cards {
      flex-wrap: wrap;
      -webkit-box-pack: center;
              justify-content: flex-start;
      padding: 10px / 2 0;
    }
    .scrollable-cards .card-h {
      -webkit-box-flex: 0;
              flex: 0 0 calc(25% - 10px);
    }
    .scrollable-cards .card-h:last-child {
      border-right: 1px solid #555555;
    }
  }
