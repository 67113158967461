// Breakpoint sizes
// Example usage @include breakpoint(x) { ... }; - where x is the device
@mixin breakpoint($bp) {
  @if $bp == xl {
    @media only screen and (min-width: $breakpoint-xl) { @content ; }
  }
  @else if $bp == l {
    @media only screen and (min-width: $breakpoint-l) { @content ; }
  }
  @else if $bp == m {
    @media only screen and (min-width: $breakpoint-m) { @content ; }
  }
}



/**
 * Computes a CSS calc function that betweens a value from
 * A to B over viewport-width A to viewport-width B.
 * Requires a media query to cap the value at B.
 */

@function between($from, $to, $fromWidth, $toWidth) {
  $slope: ($to - $from) / ($toWidth - $fromWidth);
  $base: $from - $slope * $fromWidth;

  @return calc(#{$base} + #{100vw * $slope});
}