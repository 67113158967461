/* ==========================================================================
   #Quote
   ========================================================================== */


.c-quote{
	
	font-size:                              12vw;
    line-height:                            12vw;
    margin:									0;

	@include breakpoint(m){
		font-size:                              5.5vw;
    	line-height:                            5.5vw;
	}
}