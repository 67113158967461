.pirates-masonry {
    column-gap: 40px;
    column-count: 3;
    max-width: 100%;
}

.pirates-masonry .item {
    box-sizing: border-box;
    padding: 0px;
    object-fit: contain;
    cursor: pointer;
    margin-bottom: 100px;

    -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
        page-break-inside: avoid; /* Firefox */
        break-inside: avoid; /* IE 10+ */
}

@media  (max-width: 796px) {
  .pirates-masonry {
    column-count:2;
  }

}

.pirates-masonry .item .item__content {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 40px;
      width: 100%;
      box-sizing: border-box;
}


.pirates-masonry .item .pirate-details {
    padding:20px 0;
    display:flex;
    flex-direction:column;
    position:relative;

}

.pirates-masonry .item .pirate-details h2 {
    font-size: 2rem;
    line-height: 2rem;
    margin-bottom: 10px;
}

.pirates-masonry .item .pirate-details h4 {
    font-family: "Averta Regular";
    font-size: .8rem;
    line-height: 18px;
}

.pirates-masonry .item .pirate-details .data-description {
  display:none;
  padding: 30px;
}

body.pirates .gslide-media.gslide-external {
  display:none;
}

body.pirates .glightbox-clean .gslide-desc {
  color: #000000;
}

.glightbox-clean .gslide-title {
  font-family: "Averta Black";
  text-transform: uppercase;
}

.glightbox-clean .gslide-desc,
.glightbox-modern .gslide-desc {
  font-family: "Averta Regular";
}

.glightbox-clean .gdesc-inner,
.glightbox-modern .gdesc-inner {
  padding: 80px 80px;
}

@media  (max-width: 796px) {
  body.pirates .glightbox-clean .gslide-desc {
    color: #ffffff;
  }
}
