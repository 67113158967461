/* ==========================================================================
   #FORMS
   ========================================================================== */

/**
 * Form element used to collect user input:
 * Contains input elements, like text fields, checkboxes, radio buttons, submit buttons, and more.
 */





$base-error-color:								#ff0000    !default;





.c-form{

}





.c-form-entity{

}







	
/* Form entities States
   ========================================================================== */


.c-form-entity.has-error{

	border:										1px solid $base-error-color;

}