.press-masonry {

    position: relative;
    /** /
    display: flex;
    flex-wrap: wrap;
    /**/
    display: block;
}

.press-masonry .item {
  position:relative;
  padding: 20px 100px;
  padding-left: 0;
  box-sizing: border-box;
  object-fit: contain;
  margin-bottom: 100px;
  /** /
  flex-basis: 50%;
  height: 100%;
  /**/
  float: left;
  width: 50%;

}

.press-masonry .my-sizer-element {
  width: 8.33333%;
  width: 50%;
}

.press-masonry .item:before {
  content:'';
  position: absolute;
  bottom: 0;
  left: 0;
  width:100%;
  height: 50%;

  background-color: rgba(0,0,0,0.6);
  filter: blur(20px);

}

.press-masonry .item .item__content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  width: 100%;
  box-sizing: border-box;
  border: 5px solid #e3b305;
}

.press-masonry .item .press-details {
  padding:20px 0;
  display:flex;
  flex-direction:column;
  position:relative;

}

.press-masonry .item .press-details h2 {
  font-size: 2rem;
  line-height: 2rem;
  margin: 0;
}

.press-masonry .item .press-details h2 a{
  text-decoration:none;
  line-height: 1.3;
}
.press-masonry .item .press-details h2 a:hover,
.press-masonry .item .press-details h2 a:focus {
  outline:none;
  color: #ffc600;
}

.press-masonry .item .press-details h4 {
  font-family: "Averta Regular";
  font-size: .8rem;
  line-height: 18px;
  margin:2em 0;
  text-align: left;
}

@media  (max-width: 768px) {
  .press-masonry .item {
    /** /
    column-count:1;
    flex-basis: 100%;
    /**/
    padding-right: 0;
    width: 100%;
  }
}

.news-filter {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 4em;
}

.news-filter .filter-group {
  position: relative;
}

.news-filter .filter-group details {
	position: relative;
	width: 300px;
	margin-right: 1rem;
}

@media  (max-width: 768px) {
  .news-filter {
    flex-direction: column;
  }
  .news-filter .filter-group details {
    margin-bottom: 2em;
  }
}

.news-filter .filter-group details[open] {
	z-index: 1;
}

.news-filter .filter-group summary {
	padding: 1rem;
	cursor: pointer;
	border-radius: 5px;
	background-color: #ddd;
	list-style: none;
}

.news-filter .filter-group summary::-webkit-details-marker {
	display: none;
}

.news-filter .filter-group details[open] summary:before {
	content: '';
	display: block;
	width: 100vw;
	height: 100vh;
	background: transparent;
	position: fixed;
	top: 0;
	left: 0;
}

.news-filter .filter-group summary:after {
	content: '';
	display: inline-block;
	float: right;
	width: .5rem;
	height: .5rem;
  position: relative;
  top: 10px;
	border-bottom: 1px solid #000000;
	border-left: 1px solid #000000;
	border-bottom-left-radius: 2px;
	transform: rotate(45deg) translate(50%, 0%);
	transform-origin: center center;
	transition: transform ease-in-out 100ms
}

.news-filter .filter-group summary:focus {
	outline: none;
}

.news-filter .filter-group details[open] summary:after {
	transform: rotate(-45deg) translate(0%, 0%);
}

.news-filter .filter-group ul {
	width: 100%;
	background: #ddd;
	position: absolute;
	top: calc(100% + .5rem);
	left: 0;
	padding: 1rem;
	margin: 0;
	box-sizing: border-box;
	border-radius: 5px;
  list-style: none;
  font-size: 1rem;
  /*
	max-height: 200px;
  */
	overflow-y: auto;
}

.news-filter .filter-group li {
	margin: 0;
	padding: .5rem;
	border-bottom: 1px solid #ccc;
  transition: all .35s;
}

.news-filter .filter-group li:first-child {
	padding-top: 0;
}

.news-filter .filter-group li:last-child {
	padding-bottom: 0;
	border-bottom: none;
}

.news-filter .filter-group li:hover {
  background: black;
}
.news-filter .filter-group li:hover label {
  color: #e6b505;
}

/* FAKE SELECT */

.news-filter .filter-group summary.radios {
	counter-reset: radios;
}

.news-filter .filter-group summary.radios:before {
	content: var(--selection);
}

.news-filter .filter-group input[type=radio] {
	counter-increment: radios;
	appearance: none;
	display: none;
}

.news-filter .filter-group input[type=radio]:checked {
	display: inline;
	--display: block;
}

.news-filter .filter-group input[type=radio]:after {
	content: attr(title);
	display: inline;
	font-size: 1rem;
}

.news-filter .filter-group ul.list {
	counter-reset: labels;
}

.news-filter .filter-group label {
	width: 100%;
	display: flex;
	cursor: pointer;
	justify-content: space-between;
  transition: all .35s;
}

.news-filter .filter-group label span {
	--display: none;
	display: var(--display);
	width: 1rem;
	height: 1rem;
	border: 1px solid #727272;
	border-radius: 3px;
}
