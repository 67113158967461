/* ==========================================================================
   #WIZARD
   ========================================================================== */



$wizard-timer-duration : 	11s;
$wizard-timer-width:		2px;








.c-wizard{

	position:				absolute;
	left:  					0;
	top:					0;
	right: 					0;
	bottom:  				0;
	overflow:  				hidden;		
	background-color:		$color-black;
	height:  				100vh;	

}









/* States
   ========================================================================== */


.c-wizard.is-hidden{
	opacity:				0;
	pointer-events:			none;	
}







/**
 *
 * WIZARD CASE BACKGROUND VISUALS
 *
 */




.c-wizard__visuals{

	position: 				absolute;
	top: 					0;
	left: 					0;
	right: 					0;
	bottom: 				0;
	overflow: 				hidden;

	





	@media only screen and (max-width: $breakpoint-l) {

		transform:					translateX(0) !important;
	}



	&:after{
		position: 			absolute;
		top: 				0;
		left: 				0;
		right: 				0;
		bottom: 			0;
		content: 			"";
		background: 		rgba(0,0,0,.25);
		transition:         background 600ms ease-out;
	}
}





.c-wizard__visual, .c-wizard__visual-img, .c-wizard__visual-wrapper{

	position: 				absolute;
	right: 					0;
	bottom: 				0;
	top: 					0;
	left: 					0;
	
}


.c-wizard__visual{
	background-color:		$color-black;
	opacity: 				0;

	&:first-child{
		opacity:  			1;
	}
}


.c-wizard__visual-img{

	background-size: 		cover;
    background-position: 	50% 50%;
    transition:				opacity 1s;


    opacity:				0;


    &.is-loaded{
		opacity:  			1;	
	}

}






/* Active state
   ========================================================================== */


.c-wizard__visual.is-active{

	.c-wizard__visual-wrapper{

		transition: 		$wizard-timer-duration transform linear;
		transform: 			scale(1.2);

	}
}














/**
 *
 * WIZARD CASE TITLES
 *
 */




.c-wizard__titles{

	position: 				absolute;
	width: 					100%;
	min-height: 			85vh;
	z-index: 				$z-foreground;





	@include breakpoint(l){
		min-height: 		100vh;
		width: 				60vw;
	}
	

}





.c-wizard__title{

	position:  				absolute;
	top: 					0;
	left: 					0;
	right: 					0;
	bottom: 				0;
	display: 				flex;

	justify-content: 		center;
	flex-direction: 		column;
	padding:				10%;
	padding-top:			70px;
	//padding-top:			calc(10% + 60px);

	// Hidden by default
	opacity: 				0;
    pointer-events: 		none;


    .c-button{
    	margin-left:		2%;
    }






    @include breakpoint(l){
		
		padding-left: 		20%;
		padding-top:		auto;
	}


}



.c-wizard__client{


	font-size:					1.6rem;
	line-height:				1.6rem;
	font-family:  				"Averta Semibold";
	

	@include breakpoint(l){
		font-size:				1.6vw;
		line-height:			1.6vw;
	}
}

@media only screen and (max-height:550px) { 
	.c-wizard__summary{
		display:  none;
	}


}





/* Active state
   ========================================================================== */


.c-wizard__title.is-active{
	opacity: 				1;
	pointer-events: 		auto;
}











/**
 *
 * WIZARD RESULTS
 *
 */




.c-wizard__results{

	position: 				absolute;
	overflow: 				hidden;
	
	background-color: 		rgba(0,0,0,.7);
	transform-origin: 		50% 0;
	
	left: 					0;
	right:					-1px;
	height:					15vh;
	bottom:					-1px;

	z-index:				$z-foreground;




	@include breakpoint(l){
		
		min-height: 			101vh;
		left:					55vw;
		transform: 				skewX(20deg);
		bottom:					-1px;
		top:					-1px;
		right: 					calc(-0.364 * 50vh);
	}


}
.c-wizard__results-controls {
	position: 					absolute;
	z-index: 					$z-foreground;
	top:						10vh;
	left:						0;
	right:						0;
	display: 					none;
	align-items: 				center;
	justify-content: 			center;

	@include breakpoint(l){
		display: 				flex;
	}
}














/**
 *
 * WIZARD TIMER
 *
 */




.c-wizard__timer{
	position: 				absolute;
	left: 					0;
	top:					0;
	right: 					0;
	height:					$wizard-timer-width;
	overflow: 				hidden;

	@include breakpoint(l){
		width: 					$wizard-timer-width;
		right:					auto;
		height:					auto;
		bottom:					0;
	}
}




.c-wizard__timer-track{
	
	position: 				absolute;
	left: 					0;
	top:					0;
	right: 					0;
	background-color: 		rgba(255,255,255,.2);
	height:					$wizard-timer-width;
	z-index: 				$z-foreground;


	@include breakpoint(l){
		width: 					$wizard-timer-width;
		right:					auto;
		height:					auto;
	}
}



.c-wizard__timer-progress{

	position: 				absolute;
	left: 					0;
	top:					0;
	height: 				$wizard-timer-width;
	// /right:					0;
	z-index: 				$z-foreground;
	
	background-color: 		$color-white;

	@include breakpoint(l){
		width: 					$wizard-timer-width;
		right:					auto;
		height:					auto;
		height: 				100%;
	}
	
}









/**
 *
 * WIZARD RESULTS THUMBS
 *
 */




.c-wizard__thumbs{

	position: 				absolute;
	top: 					2px;
	left: 					$wizard-timer-width;
	bottom: 				0;
	width: 					100%;

	display: 				flex;
	justify-content: 		center;
	flex-direction: 		row;





	@include breakpoint(l){
		flex-direction: 		column;
		width: 					20vh;

	}

}




.c-wizard__thumb{

	width: 					33.33%;
	height: 				20vh;
	position: 				relative;
	overflow: 				hidden;
	background-color: 		$color-black;
	cursor: 				pointer;


	@include breakpoint(l){
		width: 				100%;
		height: 			10vh;
	}

}





.c-wizard__thumb-image{

	position: 				absolute;
	width: 					125%;
	height: 				100%;
	left: 					0;
	background-size: 		cover;
	transform-origin: 		50% 0;
	
	background-position: 	50% 50%;
	opacity: 				0;

	transition: 			opacity 500ms;

	&.is-loaded{
		opacity: 				.3;
	}


	@include breakpoint(l){
		transform: 			skewX(-20deg);
	}
}






/* Thumb states
   ========================================================================== */


.c-wizard__thumb.is-active{

	.c-wizard__thumb-image{
		opacity: 			1;
	}
}



.has-no-touch .c-wizard__thumb:not(.is-active):hover{

	.c-wizard__thumb-image{
		opacity: 			.4;
	}
}






