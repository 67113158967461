/* ==========================================================================
   #LIST COMPONENT 
   ========================================================================== */




.c-list{
	font-size:					.9rem;
}








.c-list__item{
	padding:	 				2.5vh 0;
	text-decoration:			none;
	

	// &.is-link{
	// 	cursor:  				pointer;
	// }


					
}






.c-list__label{
	
	transition:					transform .5s cubic-bezier(0.77, 0, 0.175, 1);

	@include breakpoint(l){
		font-size:	 			1.3vw;
	}
}





.c-list__info{
	padding-left:				40px;
	opacity:					.6;
	align-self: 				center;
}













/* Visual states
   ========================================================================== */







.c-list--white{
	color: 						$color-white;

	.c-list__item{
		border-bottom:  		1px solid rgba(255,255,255,.2);
	}
}	





.c-list--black{
	color: 						$color-black;

	.c-list__item{
		border-bottom:  		1px solid rgba(0,0,0,.15);

		// &:first-child{
		// 	border-top:  		1px solid rgba(0,0,0,.15);
		// }
	}
}	








/* Hover states
   ========================================================================== */




.has-no-touch a.c-list__item:hover{
	.c-list__label{
		transform:				translateX(20px);
	}
}