/* ==========================================================================
   #TABLES
   ========================================================================== */

caption {
  font-size:            inherit;
  line-height:          normal;
  font-weight:          700;
  text-align:           left;
  padding:              10px;
  border-bottom:        1px solid #d7d7d7;
}
table {
  border-collapse:      collapse;
  border-spacing:       0;
  width:                100%;
  margin:               0;
  text-align:           left;
  thead,
  tbody,
  tfoot {
    td,
    th {
      padding:          10px;
      border-bottom:    1px solid #e9e9e9;
    }
  }
}