
body.careers {

	.js-scene-intro {
		//padding-top: 0;
		align-items: flex-start;
		background: #fff;
		padding-left: 0;
		padding-right: 0;
	}

	#sage .iframe-panel-heading .JobTitleLabel {
		color:#000000;
		font-family: "Averta Regular";
	}
	#sage .iframe-panel-heading .JobTitleLabel:hover {
		color: #ffc600;
	}

	#sage .job-container-button {
		color: #000000;
		background-color: #ffc600;
		font-family: "Averta Regular";
	}

	#sage .job-container-button:hover {
		color: #ffc600;
		background-color: #000000;
	}

	#sage {
		//@include breakpoint(m){
		@media only screen and (max-width: 768px) {
			margin-top: 2em;
			//height: 80vh;
		}
	}
}
