/* ==========================================================================
   #Slider component (cases page,...)
   ========================================================================== */

.c-slider{

	display:  			flex;
	align-items:		center;
	margin-top:			80px;
	max-width:   		100vw;
	overflow:  			hidden;

	@include breakpoint(m){
		margin-top:			0;
		min-height:  		100vh;
	}
}





.c-slider__container{
	width:  			100%;
}






.c-slider__items{
	position: 			relative;
    display: 			flex;
    margin: 			0;
    padding: 			0;
}





.c-slider__item{
	list-style:			none;
	display: 			block;
    flex-shrink: 		0;
    width: 				40vh;
    position: 			relative;
    cursor:  			pointer;
    transition:			filter .5s;


    @include breakpoint(m){
    	width:  		50vh;
    }
}




.c-slider__item:not(:last-child){
	margin-right:  		1rem;
}






.c-slider__visual{
	position: 			relative;
	width: 				40vh;
    height: 			30vh;
    background: 		$color-dark;
    overflow:  			hidden;
    //transform: translate3d(0, 0, 0);

     @include breakpoint(m){
    	width:  		50vh;
    }
}



.c-slider__visual__item{
	position: 			absolute;
    top: 				-17%;
    left: 				-17%;
    right: 				-17%;
    bottom: 			-17%;
    background-size: 	cover;
    transform:			skewX(-20deg) scale(1.05);
    opacity: 			0;
    transition:			opacity 1s;
    transition-delay:	.3s;

    &.is-loaded{
    	opacity: 		1;
    }

 
}





.c-slider__subtitle{
	opacity:  			.5;
}








/**
 *
 * Slider scrollbar
 *
 */




 .c-slider__scrollbar {

	position: 			relative;
	height: 			30px;
	width: 				100%;
	margin: 			0 auto;
	opacity: 			0;
	margin-top: 		10vh;
	cursor: 			pointer;
	user-select: 		none;
	transform: 			scaleX(.8);

	&:before {
		content: 		"";
		position: 		absolute;
		top: 			50%;
		left: 			0;
		right: 			0;
		height: 		2px;
		background: 	rgba(255,255,255,.2);
	}

	.c-slider__scrollbar__knob  {
		position: 		absolute;
		top: 			0;
		bottom: 		0;
		left: 			0;
		width: 			0;
		transition: 	width 250ms ease-in-out;

		&:after {
			content: 	"";
			position:	absolute;
			top: 		calc(50%);
			left: 		0;
			right: 		0;
			height: 	2px;
			background: $color-white;
		}
	}
}









/* States
   ========================================================================== */

.has-no-touch .c-slider__item{
	filter:				brightness(0.6);
}

.has-no-touch .c-slider__item:hover{

	filter:				brightness(1);

	// .c-slider__visual__item{
	// 	transform:			skewX(-20deg) scale(1);
	// }
}


