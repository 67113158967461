.o-media{

	display: 				flex;
  	align-items: 			flex-start;
}




.o-media__img{

	margin-right:			1rem
}




.o-media__body{

	flex: 					1;	
	align-self:				center;
}




@include breakpoint(m){

	.o-media\@m{

		display: 				flex;
	  	align-items: 			flex-start;
	}




	.o-media__img\@m{

		margin-right:			1rem
	}




	.o-media__body\@m{

		flex: 					1;
		align-self:				center;
	}

}