/* ==========================================================================
   #COOKIE MSG
   ========================================================================== */



.c-cookies{
	position:  						fixed;
	bottom:  						0;
	left:  							0;
	right:  						0;
	background:  					rgba(0,0,0,.8);
	padding:						15px;
	z-index:  						599;
	font-size:  					.9rem;
	

	.c-links .c-button{
		margin-right:  				15px;
	}



	@include breakpoint(m){
		padding:						30px;
		bottom:  						30px;
		right:  						30px;
		max-width:  					500px;
		left:							auto;
		font-size:  					1rem;
	}
}