/* ==========================================================================
   #VISUALS
   ========================================================================== */



.c-visuals{
	position: 					fixed;
	top:						0;	
	left:						0;	
	right:						0;	
	bottom:						0;	
	overflow: 					hidden;


	&:after{
		position: 			absolute;
		top: 				0;
		left: 				0;
		right: 				0;
		bottom: 			0;
		content: 			"";
		background: 		rgba(0,0,0,.15);
	}
}





.c-visual{
	position:					absolute;
	top:						0;	
	left:						0;	
	right:						0;	
	bottom:						0;

	background-size: 			cover;
    background-position: 		50% 50%;
    transition:					background 200ms;


	opacity: 				0;

	&:first-child{
		opacity:  			1;
	}


}