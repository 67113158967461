/* ==========================================================================
   #SELECTOR COMPONENT
   ========================================================================== */

/**
 *
 * SELECTOR OVERLAY
 *
 */

.c-selector {

  position:                         fixed;
  top:                              0;
  left:                             0;
  right:                            0;
  bottom:                           0;

  z-index:                          $z-nav + 10;

  overflow:                         hidden;


  &:before, &:after{
    position:                       absolute;
    left:                           0;
    right:                          2px;
    content:                        "";
    display:                        block;
    pointer-events:                 none;
    height:                         12.5vh;
    z-index:                        $z-nav + 11;
    opacity:                        0;
    transition:                     opacity 500ms;
  }


  &:before{
    //background-image:               linear-gradient(to bottom, black, rgba(0,0,0,0));
    @include                        scrimGradient($color-black, 'to bottom');
    top:                            0;
  }

  &:after{
    bottom:                         0;
     @include                        scrimGradient($color-black, 'to top');
  }


  @include breakpoint(l){

    top:                            0;
    bottom:                         0;


  }



}



.js-draggable-parent{
    cursor: url("../images/mouse_drag.png"), auto !important;
    min-height: 100vh;
      padding:                          15px;
      padding:  3rem 15px;
}



/**
 *
 * SELECTOR ITEM
 *
 */



.c-selector__item{

    position:                       relative;
    height:                         calc(100vh / 8);
    line-height:                    calc(100vh / 8);
    text-align:                     center;
    overflow:                       hidden;



    @include breakpoint(l){
      height:                       calc(100vh / 8);
      line-height:                  calc(100vh / 8);
    }

}

.c-selector__item-label{

    color:                        $color-white;
    text-decoration:              none;
    display:                      block;
    cursor:                       pointer;
    opacity:                      .8;
    transition:                   all 200ms;
    height:                       calc(100vh / 8);
    line-height:                  calc(100vh / 8);
    //min-width:                    50%;



    @include breakpoint(xl){
        display:                   inline-block;
        font-size:                 1vw;
        line-height:               1vw;
        height:                    calc(100vh / 8);
        line-height:               calc(100vh / 8);
    }

}








/* Hover states
   ========================================================================== */



.has-no-touch .is-ready .c-selector__item-label:hover{

    font-family:                    "Averta Black";
    font-weight:                    400;
    transform:                      scale3d(2,2,2);
    opacity:                        1;
    
}



/* Visual states
   ========================================================================== */


.c-selector.is-hidden{
    display:                        none;
}


.c-selector.is-visible{
    display:                      block;
}







/**
 *
 * BODY STATES
 *
 */



.selector-is-visible{
    overflow:                       hidden;
    position:                       fixed;
    top:                            0;
    right:                          0;
    bottom:                         0;
    left:                           0;
    z-index:                        z-background;


}