/* ==========================================================================
   #Insignt COMPONENT
   ========================================================================== */



.c-insight{

	background-color: 			$color-dark;
	position: 					relative;
	padding:					10vh 10vw;
	overflow: 					hidden;
	min-height:  				100vh;

 	 @include breakpoint(m){

 	 	h2{
 	 		font-size: 			3vw;
 	 		line-height: 		3vw;
 	 		letter-spacing:		-2px;
 	 		margin-top:			4vh;
 	 		margin-bottom:  	4vh;
 	 	}

 	 	padding:				20vh 10vw;

 	 }

}




.c-insight__inner{

	@include breakpoint(l){
		width: 					80%;
		max-width: 				1000px;
		//margin:					0 auto;
	}

}




.c-insight__contact__card{
	margin-bottom:  			2rem;
}

.c-insight__contact__card__item{
	align-items:				center;
	margin-bottom:  			0;


	@include breakpoint(m){
		margin-right:  			0;
	}
}





.c-insight__contact__card__icon{

	width: 						80px;
	text-align: 				center;

	.visual{
		width:				 	80%;
		margin-right:			20%;
		height:  				auto;
	}

	.icon{
		width:  				15px;
		height:  				auto;
		margin-right:			20%;
	}
}





.c-insight__contact__card__text a{
	text-decoration:  			none;
	font-size:					.9rem;
}

.c-insight__contact__card__text{

}

.icon--social{
	width:  					25px;
	display: block;
}
