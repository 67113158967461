@font-face{
    font-family:"Averta Black";
    src:url(https://d2z4h1m1xbtad3.cloudfront.net/tbwa_co_za/fonts/fb053a57-e3ba-4279-92f7-890ba31f5af5.eot?#iefix);
    src:url(https://d2z4h1m1xbtad3.cloudfront.net/tbwa_co_za/fonts/fb053a57-e3ba-4279-92f7-890ba31f5af5.eot?#iefix) format("eot"),
		url(https://d2z4h1m1xbtad3.cloudfront.net/tbwa_co_za/fonts/8459b584-29f5-454c-977f-c8ff7111d63a.woff2) format("woff2"),
		url(https://d2z4h1m1xbtad3.cloudfront.net/tbwa_co_za/fonts/6c21408f-7e66-4976-9069-5a55e61a2a25.woff) format("woff"),
		url(https://d2z4h1m1xbtad3.cloudfront.net/tbwa_co_za/fonts/1a33d36a-a8f5-4421-b4b2-7439543df473.ttf) format("truetype"),
		url(https://d2z4h1m1xbtad3.cloudfront.net/tbwa_co_za/fonts/c4047b0b-e64d-4398-ab1a-6a45fcb5894c.svg#c4047b0b-e64d-4398-ab1a-6a45fcb5894c) format("svg")
}
@font-face{
    font-family:"Averta Bold";
    src:url(https://d2z4h1m1xbtad3.cloudfront.net/tbwa_co_za/fonts/336bb834-89ca-421c-9757-5246d4ff0c46.eot?#iefix);
    src:url(https://d2z4h1m1xbtad3.cloudfront.net/tbwa_co_za/fonts/336bb834-89ca-421c-9757-5246d4ff0c46.eot?#iefix) format("eot"),
		url(https://d2z4h1m1xbtad3.cloudfront.net/tbwa_co_za/fonts/b16f96a2-713b-443b-9f67-0d7296ec0bc8.woff2) format("woff2"),
		url(https://d2z4h1m1xbtad3.cloudfront.net/tbwa_co_za/fonts/4b1f2217-7678-465c-951a-3f34132f0527.woff) format("woff"),
		url(https://d2z4h1m1xbtad3.cloudfront.net/tbwa_co_za/fonts/400d46a5-6944-4b8d-8f2a-3cb9f2436f70.ttf) format("truetype"),
		url(https://d2z4h1m1xbtad3.cloudfront.net/tbwa_co_za/fonts/3fe0986a-20af-4451-b5de-4f652a6c4936.svg#3fe0986a-20af-4451-b5de-4f652a6c4936) format("svg")
}
@font-face{
    font-family:"Averta Semibold";
    src:url(https://d2z4h1m1xbtad3.cloudfront.net/tbwa_co_za/fonts/be3d3544-c037-41c0-853a-5ec61084cb09.eot?#iefix);
    src:url(https://d2z4h1m1xbtad3.cloudfront.net/tbwa_co_za/fonts/be3d3544-c037-41c0-853a-5ec61084cb09.eot?#iefix) format("eot"),
		url(https://d2z4h1m1xbtad3.cloudfront.net/tbwa_co_za/fonts/59bd606f-f42a-4e4c-b2bf-2f26e11a847d.woff2) format("woff2"),
		url(https://d2z4h1m1xbtad3.cloudfront.net/tbwa_co_za/fonts/d21a2ace-0bd2-44d6-b32b-4bfee0fd8da2.woff) format("woff"),
		url(https://d2z4h1m1xbtad3.cloudfront.net/tbwa_co_za/fonts/179a05ec-4a8b-4ba1-b1dd-a528ca16d08e.ttf) format("truetype"),
		url(https://d2z4h1m1xbtad3.cloudfront.net/tbwa_co_za/fonts/0d7e89ff-276c-49b9-acdb-ce08397e99a4.svg#0d7e89ff-276c-49b9-acdb-ce08397e99a4) format("svg")
}
@font-face{
    font-family:"Averta Regular";
    src:url(https://d2z4h1m1xbtad3.cloudfront.net/tbwa_co_za/fonts/6ea28bd6-1afd-451b-82ce-26625bbab6f7.eot?#iefix);
    src:url(https://d2z4h1m1xbtad3.cloudfront.net/tbwa_co_za/fonts/6ea28bd6-1afd-451b-82ce-26625bbab6f7.eot?#iefix) format("eot"),
		url(https://d2z4h1m1xbtad3.cloudfront.net/tbwa_co_za/fonts/1f20581f-0128-4e05-8f11-0c6eb7228596.woff2) format("woff2"),
		url(https://d2z4h1m1xbtad3.cloudfront.net/tbwa_co_za/fonts/136f11d9-c2ac-4bb4-ac52-c191f4eaf715.woff) format("woff"),
		url(https://d2z4h1m1xbtad3.cloudfront.net/tbwa_co_za/fonts/8a39b9b4-03a8-4845-9fd3-6cf80bf0aa59.ttf) format("truetype"),
		url(https://d2z4h1m1xbtad3.cloudfront.net/tbwa_co_za/fonts/1f3c1339-3fb1-4607-9e96-8ebaa0d4c7d4.svg#1f3c1339-3fb1-4607-9e96-8ebaa0d4c7d4) format("svg")
}

@font-face{
    font-family:"Averta Light";
    src:url(https://d2z4h1m1xbtad3.cloudfront.net/tbwa_co_za/fonts/576870bd-6a85-4645-a139-d667ccccb5d8.eot?#iefix);
    src:url(https://d2z4h1m1xbtad3.cloudfront.net/tbwa_co_za/fonts/576870bd-6a85-4645-a139-d667ccccb5d8.eot?#iefix) format("eot"),
		url(https://d2z4h1m1xbtad3.cloudfront.net/tbwa_co_za/fonts/f61c8ec8-b46b-4c06-9697-eb0955bca158.woff2) format("woff2"),
		url(https://d2z4h1m1xbtad3.cloudfront.net/tbwa_co_za/fonts/504270c9-3dfe-4836-9db4-90141ee23c75.woff) format("woff"),
		url(https://d2z4h1m1xbtad3.cloudfront.net/tbwa_co_za/fonts/5157e8cc-f586-4297-b2c0-107f909dc89c.ttf) format("truetype"),
		url(https://d2z4h1m1xbtad3.cloudfront.net/tbwa_co_za/fonts/cacef493-4dfd-47b5-bdec-861a45872f08.svg#cacef493-4dfd-47b5-bdec-861a45872f08) format("svg")
}
