/* ==========================================================================
   #PAGE HERO
   ========================================================================== */



.o-hero{

	min-height: 					600px;
	height:  						100vh;
	display: 						flex;
	align-items: 					center;
	justify-content: 				center;
	position:  						relative;

	padding:						15px;



}



.o-hero__content{
	position:  						relative;

}



.o-hero__visual, .o-hero__video{
	position: 						absolute;
	top: 							0;
	left: 							0;
	right: 							0;
	bottom:							0;

	opacity:  						.9;
}



.o-hero__visual{
	background-size:  				cover;
	background-position:  			50% 50%;
}




.o-hero__video{

	video{
		-o-object-fit: 				cover;
	    object-fit: 				cover;
	    display: 					block;
	    width: 						100%;
	    height: 					100%;
	}
}
.o-hero__canvas {
	width: 							100%;
	height: 						100%;
	display: 						block;
}




@include breakpoint(m){

	.o-hero__title{
		font-size:					6vw;
	}

}

.o-hero__video video.is-hidden {
	display:none;
}








/**
 *
 * JS styling
 *
 */



.js-home-anim{
	opacity: 						0;
}