/* ==========================================================================
   #SLASH
   ========================================================================== */


 .c-slash{
 	position: 				fixed;
 	pointer-events:			none;
 	top: 					0;
 	left:					0;
 	z-index:  				$z-nav + 1;
 }